import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import {
  getTokenFromStorage,
  getTokenClaims,
  getTokenExpireTime,
} from './utils';

const initialState = {
  user: { email: '', fullName: '', tags: {} },
  additionalClaims: {},
  expiresAt: null,
  errorCode: null,
  clientId: null,
  redirectUri: null,
  enableDeepLinking: true,
  isAuthenticated: false,
};

export const sliceKey = 'healthyAuth';

const healthyAuthSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    setClientId(state, action) {
      state.clientId = action.payload.clientId;
    },
    setRedirectUri(state, action) {
      state.redirectUri = action.payload.redirectUri;
      state.enableDeepLinking = action.payload.enableDeepLinking;
    },
    setAuthStatus(state) {
      const stickyToken = getTokenFromStorage();
      state.isAuthenticated = Boolean(
        !state.errorCode &&
          stickyToken &&
          state.expiresAt > new Date().getTime(),
      );
    },
    setExpiryTimeFromToken(state, action) {
      state.expiresAt = getTokenExpireTime(action.payload.token);
    },
    setUserDataFromToken(state, action) {
      state.user = getTokenClaims(action.payload.token);
    },
    setErrorCode(state, action) {
      state.errorCode = action.payload.error;
    },
    additionalUserClaims(state, action) {
      state.additionalClaims = action.payload;
    },
    resetAuthData(state) {
      Object.entries(
        omit(initialState, ['clientId', 'enableDeepLinking', 'redirectUri']),
      ).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
});

export const { reducer, actions } = healthyAuthSlice;
export { initialState };
