import { setupWorker } from 'msw';
import { setupServer } from 'msw/node';

export function createMockServer({ handlers = [] }) {
  const isNodeEnv = typeof window === 'undefined';

  let server = null;

  if (isNodeEnv) {
    server = setupServer(...handlers);
  } else {
    server = setupWorker(...handlers);
  }

  server.listen = () => {
    if (isNodeEnv) {
      return server.listen();
    }

    return server.start();
  };

  server.stop = () => {
    if (isNodeEnv) {
      return server.close();
    }

    return server.stop();
  };

  return server;
}
