import PropTypes from 'prop-types';

import { cx } from '@emotion/css';
import { css } from '@emotion/react';

import { colors } from '../../../theme';

const placeholderCss = css`
  grid-area: 1/1/2/3;
  color: ${colors.gray600};
`;

function Placeholder(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { placeholder },
  } = props;

  if (!placeholder) return null;

  return (
    <div
      className={cx('HioSelect__placeholder')}
      css={placeholderCss}
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </div>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
};

export default Placeholder;
