import { css } from '@emotion/react';
import { borderRadius, colors, elevations } from '@packages/ui';

import { fontSize } from './helpers';

export default css`
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    line-height: 1.43;
  }

  a {
    line-height: normal;
    text-decoration: none;
    color: ${colors.primary};
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  input,
  textarea,
  select {
    font-family: inherit;
    outline: 0;
  }

  p {
    margin: 0;
  }

  strong {
    font-weight: 900;
  }

  ::placeholder {
    color: ${colors.gray600};
    opacity: 1; /* Firefox */
  }

  /* Microsoft Edge */
  ::-webkit-input-placeholder {
    color: ${colors.gray600};
  }

  /* Override Toast styling */
  .Toast__error {
    background-color: ${colors.danger};
  }

  .Toast__success {
    background-color: ${colors.success};
  }

  /* FormControlLabel */
  .MuiFormControlLabel-label {
    &.MuiTypography-root {
      font-size: ${fontSize.md};
    }
  }

  /* TablePagination */
  .MuiTablePagination-root {
    .MuiTypography-body2,
    .MuiTablePagination-input {
      font-size: ${fontSize.sm};
    }

    /* Previous and next icons */
    .MuiTablePagination-actions {
      .MuiIconButton-label svg {
        font-size: 24px;
      }
    }
  }

  /* Dropdown's arrow */
  .MuiSelect-icon {
    font-size: 24px;
  }

  /* Dropdown's content */
  .MuiPopover-root {
    max-height: 320px;
    .MuiPaper-root {
      border-radius: ${borderRadius.sm};
      border: 1px solid ${colors.gray400};
      box-shadow: ${elevations.elevation200};
      background: ${colors.white};
      transition: unset;

      @keyframes slideDownAndFade {
        from {
          opacity: 0;
          transform: translateY(-2px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }

      animation: slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
    }
  }

  .MuiList-root {
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  // Removing box-shadow due to conflicts with the design system.
  // TODO: Remove once fully embracing the design system
  input,
  textarea,
  select {
    :focus {
      box-shadow: unset;
    }
  }

  /* Remove clear button from search inputs */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  /* Remove arrows from input number */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
