import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';
import { borderRadius, colors, fontSizes, spacing } from '../../theme';
import { toRem } from '../../helpers';
import useThemeContext from '../Theme/useThemeContext';

const badgeCss = (props) => css`
  border-radius: ${borderRadius.sm};
  display: inline-flex;
  column-gap: ${spacing.space2};
  align-items: center;
  padding-inline: ${spacing.space2};
  font-size: ${fontSizes.caption};
  font-weight: 400;
  width: fit-content;
  height: ${toRem(24)};

  ${props.isPill &&
  css`
    border-radius: ${toRem(20)};
  `};

  ${props.withStroke &&
  css`
    border: 1px solid currentColor;
  `}
`;

const intentCss = {
  danger: css`
    color: ${colors.danger};
    background-color: ${colors.danger100};
  `,
  gray: css`
    background-color: ${colors.gray200};
  `,
  primary: css`
    color: ${colors.primary};
    background-color: ${colors.primary100};
  `,
  success: css`
    color: ${colors.success};
    background-color: ${colors.success100};
  `,
  warning: css`
    color: ${colors.warning};
    background-color: ${colors.warning100};
  `,
};

/**
 * Badges help to label, categorize information, or indicate status, by using a keyword
 */
const Badge = forwardRef(
  (
    { children, className, icon, intent, isPill, withStroke, ...restProps },
    ref,
  ) => {
    const { defaultProps } = useThemeContext();

    const _isPill = isPill ?? defaultProps?.Badge?.isPill ?? false;

    const _withStroke = withStroke ?? defaultProps?.Badge?.withStroke ?? false;

    return (
      <span
        css={[
          badgeCss({ isPill: _isPill, withStroke: _withStroke }),
          intentCss[intent],
        ]}
        className={cx(
          'HioBadge__root',
          {
            [`HioBadge--${intent}`]: Boolean(intent),
            'HioBadge--pill': _isPill,
            'HioBadge--with-stroke': _withStroke,
          },
          className,
        )}
        data-testid="badge"
        ref={ref}
        {...restProps}
      >
        <Text className="HioBadge__text">{children}</Text>
        {icon && <Icon className="HioBadge__icon" icon={icon} size="xs" />}
      </span>
    );
  },
);

Badge.defaultProps = {
  intent: 'gray',
};

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  intent: PropTypes.oneOf(['danger', 'gray', 'primary', 'success', 'warning']),
  icon: PropTypes.string,
  isPill: PropTypes.bool,
  withStroke: PropTypes.bool,
};

export default Badge;
