import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { colors, spacing } from '../../theme';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

const formErrorCss = css`
  color: ${colors.danger};
  margin-block-start: ${spacing.space2};
  display: flex;
  align-items: center;
  column-gap: ${spacing.space1};
`;

const FormError = forwardRef(({ children, className }, ref) => (
  <Text
    css={formErrorCss}
    className={cx('HioFormError__root', className)}
    ref={ref}
    variant="caption"
  >
    <Icon icon="x-circle" size="xs" />
    {children}
  </Text>
));

FormError.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FormError;
