import PropTypes from 'prop-types';

import { cx } from '@emotion/css';
import { css } from '@emotion/react';

import { toRem } from '../../../helpers';

import { colors, spacing } from '../../../theme';

const optionCss = ({ isFocused }) => css`
  height: ${toRem(40)};
  display: flex;
  align-items: center;
  padding-inline: ${spacing.space3};
  cursor: pointer;
  column-gap: ${spacing.space4};
  color ${colors.text};
  background-color : transparent;

  // Hover and focus state
  ${
    isFocused &&
    css`
      background-color: ${colors.blueGray200};
    `
  }

  
`;

function Option(props) {
  const { children, isFocused, innerProps, innerRef } = props;

  return (
    <div
      className={cx('HioSelect__option', {
        'HioSelect__option--is-focused': isFocused,
      })}
      css={[optionCss({ isFocused })]}
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </div>
  );
}

Option.propTypes = {
  children: PropTypes.node.isRequired,
  isFocused: PropTypes.bool.isRequired,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default Option;
