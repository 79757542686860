import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Visibility from 'visibilityjs';

import { reporter } from '@packages/reporter';
import { toast } from '@packages/ui';
import useInterceptHealthyAuth from './useInterceptHealthyAuth';

import { actions } from './slice';
import { selectAuthState } from './selectors';
import {
  getTokenFromStorage,
  logoutAndInvalidate,
  saveTokenToStorage,
  getTokenExpireTime,
} from './utils';
import getInitialAuthToken from './getInitialAuthToken';
import getUserClaims from './services/getUserClaims';

let expiryTimeout = null;

function AuthProvider({
  apiUrls,
  AuthenticatedApp,
  UnauthenticatedApp,
  clientId,
  redirectUri,
  enableDeepLinking = true,
  options,
  appLogout,
}) {
  const [visible, setVisible] = useState(true);
  const [authInitialized, setAuthInitialized] = useState(false);

  useInterceptHealthyAuth({ apiUrls, appLogout });

  const dispatch = useDispatch();
  const state = useSelector(selectAuthState);
  const { expiresAt, isAuthenticated } = state;

  function onLogout() {
    if (appLogout) {
      appLogout();
    } else {
      dispatch(actions.resetAuthData());
      logoutAndInvalidate();
    }
  }

  function onError(error) {
    dispatch(actions.setErrorCode({ error }));
  }

  // Initialize auth token
  useEffect(() => {
    // When refreshToken custom event fires (utils/handleTokenRefresh)
    // Update the state with the new token and expiresAt
    function applyRefreshToken(ev) {
      const { refreshToken } = ev.detail;
      if (!refreshToken) return;
      saveTokenToStorage(refreshToken);
      dispatch(actions.setExpiryTimeFromToken({ token: refreshToken }));
    }

    async function initAuthentication() {
      let token;
      let tokenIsValid;
      let claims;

      const withClaims = Boolean(
        options?.userClaimsUrl || options?.userClaimsMultiRegion,
      );

      // clientId and redirectUri will not change and needed by useHealthyAuth
      // So we're setting them first
      dispatch(actions.setClientId({ clientId }));
      dispatch(actions.setRedirectUri({ redirectUri, enableDeepLinking }));

      try {
        // Check QS for token or alternative UM flow
        token = await getInitialAuthToken(
          options,
          clientId,
          redirectUri,
          onError,
        );

        // If no token was received from QS or from exchange of a singleUseToken, check localStorage
        if (!token) token = getTokenFromStorage();

        if (token) {
          // Check token validity
          tokenIsValid = getTokenExpireTime(token) > new Date().getTime();

          // Persisting the token if valid.
          // NOTE: We need it in storage to issue the getUserClaims request
          if (tokenIsValid) saveTokenToStorage(token);

          // If it's valid, fetch user claims (for apps which have a claims key in the options)
          if (tokenIsValid && withClaims) {
            try {
              claims = await getUserClaims(options, token);
            } catch (err) {
              reporter.error(err, { context: 'Error fetching user claims' });
              toast.error('Error fetching user claims');

              if (err.meta?.statusCode === 401) {
                onLogout();
              }
              tokenIsValid = false;
            }
          }
        }
      } catch (err) {
        reporter.error(err, { context: 'Error fetching auth token' });
        toast.error('Error fetching token');

        tokenIsValid = false;
      } finally {
        // If all went well, persist the token to Redux store
        if (token && tokenIsValid) {
          dispatch(actions.setExpiryTimeFromToken({ token }));
          dispatch(actions.setUserDataFromToken({ token }));
        }
        // Persist additional claims to Redux store if available
        if (claims) {
          dispatch(actions.additionalUserClaims(claims));

          /**
           * Add user-claims to local-storage.
           *
           * This is being done in order to bypass a bug in which apps are missing
           * additional user-claims when logging in different tabs.
           *
           * Ideally, we won't place it in local-storage. Instead we would refetch user-claims.
           *
           * @see
           * - https://github.com/OwnHealthIL/healthy-web/issues/4881
           * - https://github.com/OwnHealthIL/healthy-web/issues/4471
           */
          localStorage.setItem('userClaims', JSON.stringify(claims));
        }

        // Keep tab visibility synced
        Visibility.change(() => {
          setVisible(!Visibility.hidden());
        });

        window.addEventListener('refreshToken', applyRefreshToken);

        setAuthInitialized(true);
      }
    }

    initAuthentication();
  }, []);

  // Manage token expire time
  useEffect(() => {
    function manageExpiryTimeout(expireTime) {
      clearTimeout(expiryTimeout);
      // If null/not set, nothing to do
      // And if tab not visible, we don't want to set a timeout
      if (!expireTime || !visible) return;

      // Get current time in ms
      const now = new Date().getTime();
      // Check that token isn't already expired
      if (expireTime > now) {
        const timeLeft = expireTime - now;
        // If still valid, set the timeout
        expiryTimeout = setTimeout(onLogout, timeLeft);
        // If already expired, sign out
      } else {
        onLogout();
      }
    }

    if (authInitialized) {
      manageExpiryTimeout(expiresAt);
      dispatch(actions.setAuthStatus());
    }
  }, [expiresAt, visible, authInitialized]);

  // When a tab becomes visible, update token (if there's a new one) and trigger new expiry timeout.
  useEffect(() => {
    if (authInitialized) {
      const stickyToken = getTokenFromStorage();

      if (visible && stickyToken) {
        dispatch(actions.setExpiryTimeFromToken({ token: stickyToken }));
        dispatch(actions.setUserDataFromToken({ token: stickyToken }));
      }
    }
  }, [visible, authInitialized]);

  if (!authInitialized) {
    // TODO: Use DefaultLoader instead of null
    return options?.CustomLoader ?? null;
  }

  return isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />;
}

AuthProvider.propTypes = {
  /** Append authentication headers to the URLs listed in this list */
  apiUrls: PropTypes.arrayOf(PropTypes.string),
  AuthenticatedApp: PropTypes.elementType.isRequired,
  UnauthenticatedApp: PropTypes.elementType.isRequired,
  clientId: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  enableDeepLinking: PropTypes.bool,
  options: PropTypes.shape({
    singleUseTokenUrl: PropTypes.string,
    userClaimsUrl: PropTypes.string,
    userClaimsMultiRegion: PropTypes.shape({
      uk: PropTypes.string,
      us: PropTypes.string,
    }),
    singleUseTokenMultiRegion: PropTypes.shape({
      uk: PropTypes.string,
      us: PropTypes.string,
    }),
    externalAuthProviders: PropTypes.arrayOf(PropTypes.string),
    CustomLoader: PropTypes.node,
  }),
  appLogout: PropTypes.func,
};

export { AuthProvider };
