import { css } from '@emotion/react';
import { colors, fontSizes } from '../theme';

export const globalCss = css`
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background-color: ${colors.gray100};
    color: ${colors.text};
    letter-spacing: 0.11px;
    line-height: 1.25;
    font-family: var(--font-family-primary);
    font-size: ${fontSizes.body2};
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  input,
  textarea,
  select,
  button {
    font-family: inherit;
  }

  input,
  textarea,
  select {
    :focus {
      outline: 0;
    }
  }

  button {
    :focus-visible {
      outline: 0;
      box-shadow: var(--shadow-focus-ring);
    }
  }

  ::placeholder {
    color: ${colors.gray600};
    opacity: 1; /* Firefox */
  }

  a {
    :focus-visible {
      outline: 0;
      box-shadow: var(--shadow-focus-ring);
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
`;
