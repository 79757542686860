import { FlowStatus } from '@ownhealthil/urine-heart-api-client';

export const ENV_TYPES = {
  DEVELOP: 'develop',
  STAGING: 'staging',
  PROD_US: 'prod-us',
  PROD_UK: 'prod-uk',
};

export const ENV_LABELS = {
  [ENV_TYPES.DEVELOP]: 'HEART Develop',
  [ENV_TYPES.STAGING]: 'HEART Staging',
  [ENV_TYPES.PROD_US]: 'HEART US',
  [ENV_TYPES.PROD_UK]: 'HEART UK',
};

export const envConfig = {
  isDevelop: process.env.HEALTHY_ENV === 'develop',
  isStaging:
    process.env.NODE_ENV === 'production' &&
    process.env.HEALTHY_ENV === 'staging',
  isProd:
    process.env.NODE_ENV === 'production' &&
    process.env.HEALTHY_ENV?.includes('prod'),
};

export const adminsProdList = [
  'alison.adams@healthy.io',
  'guido.senderowitsch@healthy.io',
  'mustafa.nadim@healthy.io',
  'nurit.yeshurun@healthy.io',
  'katie.williams@healthy.io',
  'ruslana.frazer@healthy.io',
  'stephen.thompson@healthy.io',
  'talya.sassoon@healthy.io',
  'urine.backend@healthy.io',
  'urinecommercialautomation@healthy.io',
  'zoe.botta@healthy.io',
  'kellie.jones@healthy.io',
  'rebecca.forbes@healthy.io',
  'william.sutherland@healthy.io',
  'nitai.fine@healthy.io',
  'shachar.pearl@healthy.io',
  "tammy.d'amato@healthy.io",
  'madelyn.palmer@healthy.io',
];

export const Locales = {
  UK: 'uk',
  US: 'us',
};

export const QueryKeys = {
  Import: {
    FILES: '[Import] Files',
    FILES_IN_PROGRESS: '[Import] Files in progress',
    FILE_REPORT: '[Import] File report',
  },
  Onboarding: {
    COHORT: '[Onboarding] Cohort',
    ONBOARDING_FLOWS: '[Onboarding] Onboarding flows',
    ORG_UNITS: '[Onboarding] Organization units',
  },
  Partner: {
    CONFIG: '[Partner] Config',
    ORG_UNITS: '[Partner] Organization units',
    TAGS: '[Partner] Tags',
  },
  OrgUnits: {
    TAGS: '[OrgUnits] Tags',
  },
  PatientList: {
    PATIENTS: '[PatientList] Patients',
  },
  Pcp: {
    PATIENTS: '[Pcp] Patients',
  },
};

export const ERRORS = {
  DUPLICATE_PHONE_NUMBER: 'DUPLICATE_PHONE_NUMBER',
  INVALID_ADDRESS: 'INVALID_ADDRESS',
  INVALID_INPUT: 'INVALID_INPUT',
  MISSING_PHONE_NUMBER: 'MISSING_PHONE_NUMBER',
};

export const ERROR_LABELS = {
  [ERRORS.DUPLICATE_PHONE_NUMBER]:
    "Failed to update the patient. The app's unique identity is already attached to another patient.",
  [ERRORS.INVALID_ADDRESS]: 'Invalid address',
  [ERRORS.INVALID_INPUT]: 'Invalid input',
  [ERRORS.MISSING_PHONE_NUMBER]: 'Failed to send SMS. Phone number is missing',
};

export const QueueStates = {
  CONSENTED: 'consented',
  COOLING_OFF: 'cooling_off',
  DECLINED: 'declined',
  INELIGIBLE: 'ineligible',
  NO_RESPONSE: 'no_response',
  OPTED_OUT: 'opted_out',
  PENDING_CONSENT: 'pending_consent',
  PENDING_CONSENT_WITH_KIT: 'pending_consent_with_kit',
  PENDING_FOLLOW_UP_TEST: 'pending_follow_up_test',
  PENDING_KIT_FULFILLMENT: 'pending_kit_fulfillment',
  PENDING_ONBOARDING: 'pending_onboarding',
  PENDING_PRE_APPROVAL: 'pending_pre_approval',
  PENDING_SERVICE_QUALIFICATION: 'pending_service_qualification',
  PENDING_TEST: 'pending_test',
  PENDING_WARM_UP: 'pending_warm_up',
  TEST_COMPLETED: 'test_completed',
  TEST_ERROR: 'test_error',
  TEST_FAILED: 'test_failed',
  SERVICE_ENDED: 'service_ended',
  UNAUTHORIZED: 'unauthorized',
};

export const QUEUE_STATE_LABELS = {
  [QueueStates.CONSENTED]: 'Consented',
  [QueueStates.COOLING_OFF]: 'Cooling off',
  [QueueStates.DECLINED]: 'Opt out - declined',
  [QueueStates.INELIGIBLE]: 'Ineligible',
  [QueueStates.OPTED_OUT]: 'Opted out',
  [QueueStates.PENDING_CONSENT]: 'Pending consent',
  [QueueStates.PENDING_FOLLOW_UP_TEST]: 'Pending follow-up test',
  [QueueStates.PENDING_ONBOARDING]: 'Pending onboarding',
  [QueueStates.PENDING_PRE_APPROVAL]: 'Pending pre-approval',
  [QueueStates.PENDING_SERVICE_QUALIFICATION]: 'Pending service qualification',
  [QueueStates.PENDING_TEST]: 'Pending test',
  [QueueStates.TEST_COMPLETED]: 'Test completed',
  [QueueStates.TEST_FAILED]: 'Test failed',
  [QueueStates.UNAUTHORIZED]: 'Unauthorized',
  [QueueStates.PENDING_CONSENT_WITH_KIT]: 'Pending consent with kit',
  [QueueStates.PENDING_KIT_FULFILLMENT]: 'Pending kit fulfillment',
  [QueueStates.PENDING_WARM_UP]: 'Pending warmup',
  [QueueStates.TEST_ERROR]: 'Test error',
  [QueueStates.NO_RESPONSE]: 'No response',
  [QueueStates.SERVICE_ENDED]: 'Service ended',
};

export const FLOW_STATUS_LABELS = {
  [FlowStatus.Consented]: 'Consented',
  [FlowStatus.CoolingOff]: 'Cooling off',
  [FlowStatus.Declined]: 'Opt out - declined',
  [FlowStatus.Ineligible]: 'Ineligible',
  [FlowStatus.OptedOut]: 'Opted out',
  [FlowStatus.PendingConsent]: 'Pending consent',
  [FlowStatus.PendingFollowUpTest]: 'Pending follow-up test',
  [FlowStatus.PendingOnboarding]: 'Pending onboarding',
  [FlowStatus.PendingPreApproval]: 'Pending pre-approval',
  [FlowStatus.PendingServiceQualification]: 'Pending service qualification',
  [FlowStatus.PendingTest]: 'Pending test',
  [FlowStatus.TestCompleted]: 'Test completed',
  [FlowStatus.TestFailed]: 'Test failed',
  [FlowStatus.Unauthorized]: 'Unauthorized',
};

export const Scripts = {
  CONSENT: 'consent',
  CONSENT_CALL_FIRST: 'consent-call-first',
  CONSENT_KIT_FIRST: 'consent-kit-first',
  FAX_CONFIRMATION: 'fax-confirmation',
  REMINDER: 'reminder',
  SUPPORT: 'support',
  PCP_CONFIRMATION: 'pcp-confirmation',
  TEST_FAILED: 'test-failed',
};

export const Partners = {
  CINGA: 'cinga',
  MEDICA: 'medica',
};

export const SCRIPT_LABELS = {
  [Scripts.CONSENT]: 'Consent',
  [Scripts.CONSENT_CALL_FIRST]: 'Consent',
  [Scripts.CONSENT_KIT_FIRST]: 'Consent',
  [Scripts.REMINDER]: 'Reminder',
  [Scripts.SUPPORT]: 'Support',
  [Scripts.PCP_CONFIRMATION]: 'PCP confirmation',
  [Scripts.TEST_FAILED]: 'Test failed',
};

export const ACTIVITIES = {
  APP_VERIFICATION: 'appVerification',
  EMAIL: 'email',
  LETTER: 'letter',
  OUTGOING_SMS: 'outgoingSms',
  INCOMING_SMS: 'incomingSms',
  OUTGOING_CALL: 'outgoingCall',
  INCOMING_CALL: 'incomingCall',
  TEST_RESULTS: 'testResult',
  KIT_SHIPMENT: 'kitShipment',
  NOTE: 'note',
  PRIORITIZED: 'prioritized',
  SUPPORT_TICKET: 'supportTicket',
};

export const ACTIVITY_LABELS = {
  [ACTIVITIES.APP_VERIFICATION]: 'App Verification',
  [ACTIVITIES.EMAIL]: 'Email',
  [ACTIVITIES.LETTER]: 'Letter',
  [ACTIVITIES.OUTGOING_SMS]: 'Outgoing SMS',
  [ACTIVITIES.INCOMING_SMS]: 'Incoming SMS',
  [ACTIVITIES.OUTGOING_CALL]: 'Outgoing Call',
  [ACTIVITIES.INCOMING_CALL]: 'Incoming Call',
  [ACTIVITIES.TEST_RESULTS]: 'Test Result',
  [ACTIVITIES.KIT_SHIPMENT]: 'Kit Shipment',
  [ACTIVITIES.NOTE]: 'Note',
  [ACTIVITIES.PRIORITIZED]: 'Prioritized',
  [ACTIVITIES.SUPPORT_TICKET]: 'Support Ticket',
};

export const ACTIVITY_STATUSES = {
  ERROR: 'error',
  SENT: 'sent',
  SCHEDULED: 'scheduled',
  PENDING: 'pending',
  OPENED: 'opened',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
  DELAYED: 'delayed',
  ANSWERED: 'answered',
  NOT_ANSWERED: 'notAnswered',
  NOT_ANSWERED_VOICEMAIL_LEFT: 'notAnsweredVoicemailLeft',
  UNREACHABLE: 'unreachable',
  COMPLETED: 'completed',
  ARRIVED: 'arrived',
  PENDING_PATIENT_VERIFICATION: 'pendingPatientVerification',
  PATIENT_VERIFIED: 'patientVerified',
};

export const ACTIVITY_STATUS_LABELS = {
  [ACTIVITY_STATUSES.ERROR]: 'Error',
  [ACTIVITY_STATUSES.SENT]: 'Sent',
  [ACTIVITY_STATUSES.SCHEDULED]: 'Sent to Provider',
  [ACTIVITY_STATUSES.PENDING]: 'Pending to be Sent',
  [ACTIVITY_STATUSES.OPENED]: 'Opened',
  [ACTIVITY_STATUSES.CANCELED]: 'Canceled',
  [ACTIVITY_STATUSES.REJECTED]: 'Rejected',
  [ACTIVITY_STATUSES.RESOLVED]: 'Resolved',
  [ACTIVITY_STATUSES.DELAYED]: 'Delayed',
  [ACTIVITY_STATUSES.ANSWERED]: 'Patient Answered',
  [ACTIVITY_STATUSES.NOT_ANSWERED]: "Patient Didn't Answer",
  [ACTIVITY_STATUSES.NOT_ANSWERED_VOICEMAIL_LEFT]:
    "Patient Didn't Answer, VoiceMail was Left",
  [ACTIVITY_STATUSES.UNREACHABLE]: 'Patient Unreachable',
  [ACTIVITY_STATUSES.COMPLETED]: 'Completed',
  [ACTIVITY_STATUSES.ARRIVED]: 'Arrived',
  [ACTIVITY_STATUSES.PENDING_PATIENT_VERIFICATION]:
    "Patient isn't verified yet",
  [ACTIVITY_STATUSES.PATIENT_VERIFIED]: 'Patient Verified',
};

export const ACTIVITIES_ICONS = {
  [ACTIVITIES.APP_VERIFICATION]: 'user-check',
  [ACTIVITIES.TEST_RESULTS]: 'clipboard-notes',
  [ACTIVITIES.KIT_SHIPMENT]: 'minuteful-kit',
};

export const ACTIVITY_STATUS_SKIN = {
  [ACTIVITY_STATUSES.ANSWERED]: 'success',
  [ACTIVITY_STATUSES.ARRIVED]: 'success',
  [ACTIVITY_STATUSES.OPENED]: 'success',
  [ACTIVITY_STATUSES.SENT]: 'success',
  [ACTIVITY_STATUSES.COMPLETED]: 'success',
  [ACTIVITY_STATUSES.ERROR]: 'danger',
  [ACTIVITY_STATUSES.UNREACHABLE]: 'danger',
  [ACTIVITY_STATUSES.REJECTED]: 'danger',
  [ACTIVITY_STATUSES.DELAYED]: 'warning',
  [ACTIVITY_STATUSES.SCHEDULED]: 'warning',
  [ACTIVITY_STATUSES.PATIENT_VERIFIED]: 'success',
};

export const SMS_TYPES = {
  APP_DOWNLOAD_VIDEO: 'appDownloadVideo',
  CONTACT_DETAILS: 'contactDetails',
  DELIVERY: 'delivery',
  DOWNLOAD_APP: 'downloadApp',
  DOWNLOAD_APP_ONLY_LINK: 'downloadAppOnlyLink',
  END_OF_SERVICE: 'endOfService',
  FINAL_TEN_DAYS: 'final10Days',
  FINAL_THREE_DAYS: 'final3Days',
  FINAL_TWO_DAYS: 'final2Days',
  FINAL_TWENTY_DAYS: 'final20Days',
  FOLLOW_UP_1: 'followUp1',
  FOLLOW_UP_1_B: 'followUp1b',
  FOLLOW_UP_2: 'followUp2',
  FOLLOW_UP_3: 'followUp3',
  GENERIC_ONE_TIME_SMS: 'genericOneTimeSms',
  KIT_ARRIVAL: 'kitArrival',
  LAST_CHANCE: 'lastChance',
  OUTREACH_NO_WARMUP: 'outreachNoWarmup',
  OUTREACH_WITH_WARMUP: 'outreachWithWarmup',
  POST_CONSULTATION: 'postConsultation',
  REMINDER_ONE: 'reminderOne',
  REMINDER_TWO: 'reminderTwo',
  REMINDER_THREE: 'reminderThree',
  REMINDER_NEAR_END: 'reminderNearEnd',
  RESULTS: 'results',
  SECOND_KIT_ARRIVED: 'secondKitArrived',
  SECOND_KIT_DELIVERY: 'secondKitDelivery',
  TESTIMONIAL: 'testimonial',
  UPDATE_DATA: 'updateData',
  VERIFICATION: 'verification',
  WARMUP: 'warmup',
  WARMUP_CALL_FIRST: 'warmupCallFirst',
};

export const ONBOARDING_FLOW_TYPES = {
  CALL_FIRST: 'callFirst',
  KIT_FIRST: 'kitFirst',
};

export const ONBOARDING_FLOW_TYPE_LABELS = {
  [ONBOARDING_FLOW_TYPES.CALL_FIRST]: 'Call flow',
  [ONBOARDING_FLOW_TYPES.KIT_FIRST]: 'Kit flow',
};

export const SMS_TYPE_LABEL_BY_LOCALE = {
  [Locales.US]: {
    [SMS_TYPES.APP_DOWNLOAD_VIDEO]: 'App download video',
    [SMS_TYPES.CONTACT_DETAILS]: 'Contact details',
    [SMS_TYPES.DELIVERY]: 'Delivery',
    [SMS_TYPES.DOWNLOAD_APP]: 'Download app',
    [SMS_TYPES.DOWNLOAD_APP_ONLY_LINK]: 'Download app (only link)',
    [SMS_TYPES.END_OF_SERVICE]: 'End of service',
    [SMS_TYPES.FINAL_TEN_DAYS]: 'Final 10 days',
    [SMS_TYPES.FINAL_TWO_DAYS]: 'Final 2 days',
    [SMS_TYPES.FINAL_TWENTY_DAYS]: 'Final 20 days',
    [SMS_TYPES.FOLLOW_UP_1]: 'Follow-up 1',
    [SMS_TYPES.FOLLOW_UP_1_B]: 'Follow-up 1B',
    [SMS_TYPES.FOLLOW_UP_2]: 'Follow-up 2',
    [SMS_TYPES.FOLLOW_UP_3]: 'Follow-up 3',
    [SMS_TYPES.GENERIC_ONE_TIME_SMS]: 'Generic one time SMS',
    [SMS_TYPES.KIT_ARRIVAL]: 'Kit Arrival',
    [SMS_TYPES.WARMUP]: 'Warmup',
    [SMS_TYPES.LAST_CHANCE]: 'Final SMS',
    [SMS_TYPES.OUTREACH_NO_WARMUP]: 'Outreach no warmup',
    [SMS_TYPES.OUTREACH_WITH_WARMUP]: 'Outreach with warmup',
    [SMS_TYPES.POST_CONSULTATION]: 'Post consultation',
    [SMS_TYPES.REMINDER_NEAR_END]: 'Reminder near end',
    [SMS_TYPES.VERIFICATION]: 'Verification',
  },
  [Locales.UK]: {
    [SMS_TYPES.DOWNLOAD_APP]: 'Download app',
    [SMS_TYPES.END_OF_SERVICE]: 'End of service',
    [SMS_TYPES.FINAL_THREE_DAYS]: 'Final 3 days',
    [SMS_TYPES.FINAL_TWO_DAYS]: 'Final 2 days',
    [SMS_TYPES.FINAL_TWENTY_DAYS]: 'Final 20 days',
    [SMS_TYPES.GENERIC_ONE_TIME_SMS]: 'Generic one time SMS',
    [SMS_TYPES.LAST_CHANCE]: 'Invite to consent',
    [SMS_TYPES.REMINDER_ONE]: 'Reminder one',
    [SMS_TYPES.REMINDER_TWO]: 'Reminder two',
    [SMS_TYPES.REMINDER_THREE]: 'Reminder three',
    [SMS_TYPES.REMINDER_NEAR_END]: 'Reminder near end',
    [SMS_TYPES.RESULTS]: 'Results',
    [SMS_TYPES.SECOND_KIT_ARRIVED]: 'Second kit arrived',
    [SMS_TYPES.SECOND_KIT_DELIVERY]: 'Second kit delivery',
    [SMS_TYPES.TESTIMONIAL]: 'Testimonial',
    [SMS_TYPES.UPDATE_DATA]: 'Update data',
    [SMS_TYPES.VERIFICATION]: 'Verification',
    [SMS_TYPES.WARMUP]: 'Warmup',
    [SMS_TYPES.WARMUP_CALL_FIRST]: 'Warmup call first',
  },
};

export const SEND_SMS_STATUSES = {
  ERROR: 'error',
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
  SENT: 'sent',
};

export const TEST_RESULTS_STATUSES = {
  AFTER_PCP: 'after_pcp',
  ALGO_ERROR: 'algo_error',
  BOUNDARY_CONDITION: 'boundary_condition',
  CLIENT_PENDING_PCP: 'client_pending_pcp',
  CLIENT_TIMEOUT: 'client_timeout',
  HAS_RESULT: 'has_results',
};

export const FULFILLMENT_TYPES = {
  ADDRESS_VALIDATION: 'address_validation',
  DELIVERY_LETTER: 'delivery_letter',
  LAST_CHANCE_LETTER: 'last_chance_letter',
  KIT: 'kit',
  RESULTS_LETTER: 'results_letter',
  WARM_UP_LETTER: 'warm_up_letter',
  WELCOME_LETTER: 'welcome_letter',
};

export const FULFILLMENT_TYPE_LABELS = {
  [FULFILLMENT_TYPES.ADDRESS_VALIDATION]: 'Address validation by Spire',
  [FULFILLMENT_TYPES.DELIVERY_LETTER]: 'Delivery letter',
  [FULFILLMENT_TYPES.LAST_CHANCE_LETTER]: 'Last chance letter',
  [FULFILLMENT_TYPES.KIT]: 'Kit',
  [FULFILLMENT_TYPES.RESULTS_LETTER]: 'Results letter',
  [FULFILLMENT_TYPES.WARM_UP_LETTER]: 'Warmup letter',
  [FULFILLMENT_TYPES.WELCOME_LETTER]: 'Welcome letter',
};

export const FULFILLMENT_STATUSES = {
  ACCEPTED: 'accepted',
  ARRIVED: 'arrived',
  CANCELED: 'canceled',
  ERROR: 'error',
  PENDING: 'pending',
  SENT: 'sent',
  SHIPPED: 'shipped',
};

export const FULFILLMENT_STATUS_LABELS = {
  [FULFILLMENT_STATUSES.ACCEPTED]: 'accepted',
  [FULFILLMENT_STATUSES.ARRIVED]: 'arrived',
  [FULFILLMENT_STATUSES.CANCELED]: 'canceled',
  [FULFILLMENT_STATUSES.ERROR]: 'error',
  [FULFILLMENT_STATUSES.PENDING]: 'pending',
  [FULFILLMENT_STATUSES.SENT]: 'sent to fulfillment',
  [FULFILLMENT_STATUSES.SHIPPED]: 'shipped',
};

export const EMAIL_TYPES = {
  DELIVERY: 'delivery',
  FINAL_2_DAYS: 'final_2_days',
  FOLLOW_UP_1: 'follow_up_1',
  WARM_UP: 'warm_up',
};

export const EMAIL_TYPE_LABELS = {
  [EMAIL_TYPES.DELIVERY]: 'Delivery',
  [EMAIL_TYPES.FINAL_2_DAYS]: 'Final two days',
  [EMAIL_TYPES.FOLLOW_UP_1]: 'Follow-up 1',
  [EMAIL_TYPES.WARM_UP]: 'Warmup',
};

export const CONSENT_STATUSES = {
  CONSENTED: 'consented',
  DECLINED: 'declined',
  INELIGIBLE: 'ineligible',
};

export const CALL_DISPOSITION = {
  ANSWERED: 'answered',
  UNANSWERED: 'unanswered',
};

export const CALL_DISPOSITION_LABELS = {
  [CALL_DISPOSITION.ANSWERED]: 'Answered',
  [CALL_DISPOSITION.UNANSWERED]: 'Unanswered',
};

export const CallDispositionLabels = {
  ANSWERED: 'Answered',
  UNANSWERED: 'Unanswered',
  CALL_ANSWERED: 'Call Answered',
  CALL_UNANSWERED: 'Call Unanswered',
};

export const OUTCOME = {
  CALL_LATER: 'callLater',
  CONSENTED: 'consented',
  DECLINED: 'declined',
  DO_NOT_CALL: 'doNotCall',
  INELIGIBLE: 'ineligible',
  INDETERMINATE: 'indeterminate',
  NO_VOICEMAIL_LEFT: 'noVoicemailLeft',
  NOTE_ONLY: 'noteOnly',
  REMINDER_COMPLETED: 'reminderCompleted',
  SUPPORT_COMPLETED: 'supportCompleted',
  UNREACHABLE_ANSWERED: 'unreachableAnswered',
  UNREACHABLE_UNANSWERED: 'unreachableUnanswered',
  VOICEMAIL_LEFT: 'voicemailLeft',
};

export const OUTCOME_LABELS = {
  [OUTCOME.CALL_LATER]: "Can't talk - Call later",
  [OUTCOME.CONSENTED]: 'Consented - Interested in the test',
  [OUTCOME.DECLINED]: 'Opt out - Declined',
  [OUTCOME.DO_NOT_CALL]: 'Do not call',
  [OUTCOME.INELIGIBLE]: 'Ineligible',
  [OUTCOME.INDETERMINATE]: 'Indeterminate',
  [OUTCOME.NO_VOICEMAIL_LEFT]: 'No VM left',
  [OUTCOME.NOTE_ONLY]: 'Note only',
  [OUTCOME.REMINDER_COMPLETED]: 'Reminder call completed',
  [OUTCOME.SUPPORT_COMPLETED]: 'Support call completed',
  [OUTCOME.UNREACHABLE_ANSWERED]: 'Answered - Unreachable',
  [OUTCOME.UNREACHABLE_UNANSWERED]: 'Unreachable',
  [OUTCOME.VOICEMAIL_LEFT]: 'Left VM',
};

export const OPT_OUT_REASONS = {
  DECLINED: 'declined',
  INELIGIBLE: 'ineligible',
};

export const Elaborations = {
  ALL_NUMBERS_ARE_WRONG: 'All provided numbers are wrong',
  ALREADY_DID_ACR_TEST: 'Already did an ACR test in the past 12 months',
  ALREADY_SEEING_KIDNEY_DOCTOR: 'Already seeing a kidney doctor',
  ALREADY_TESTED_NOW_DECLINES:
    'Patient tried the test but now declines service',
  AT_WORK: 'At work',
  BRING_SAMPLE: 'Would rather bring a sample to surgery',
  BUSY: 'Busy',
  BUSY_TONE: 'Busy tone',
  CANT_GET_THROUGH: "Can't get through to the patient",
  CARE_HOME_RESIDENT: 'Care home resident',
  COMPLETED_TEST_WANTS_TO_BE_REMOVED:
    'Patient completed the test and wants details removed',
  COUNTRY_PRACTICE_HOSPITAL:
    'Out of country / no longer at practice / in hospital',
  CLINICALLY_INELIGIBLE_UK:
    'Clinically ineligible (e.g. pregnant, not diabetic, on dialysis, end of life, catheter-in-situ, unable to provide a sample, incontinent)',
  DECEASED: 'Deceased',
  DISENROLLED: 'Disenrolled from plan',
  DOES_NOT_SPEAK_ENGLISH: 'Does not speak English',
  DOES_NOT_SPEAK_ENGLISH_SPANISH: 'Does not speak English or Spanish',
  DONT_CALL: 'Do not call',
  DONT_CALL_AGAIN: 'Does not want to be called again',
  DONT_CALL_HUNG_UP: 'Do not call / Hung up',
  DRIVING: 'Driving',
  GAVE_SAMPLE_HAVE_APPOINTMENT:
    'Just gave a sample / Already have an appointment',
  HANG_UP_BEFORE_CONNECTION: 'Hang up before connection',
  HUNG_UP_PRIOR_TO_VALIDATION: 'Hung up prior to validation',
  INCOMPATIBLE_PHONE: 'Incompatible phone',
  INVALID_NUMBER: 'Invalid number',
  LACK_OF_CONFIDENCE: 'Lack of confidence with apps and self testing tests',
  LOW_DIGITAL_LITERACY: 'Low digital literacy',
  LOW_DIGITAL_LITERACY_AND_REFUSED_PEA_ASSISTANCE:
    'Low digital literacy & refused PEA assistance',
  MEMBER_IS_BUSY: 'Member is busy',
  MEMBER_IS_ASSISTED_LIVING:
    'Member is in assisted living facility or nursing home',
  MEDICAL_QUESTIONS_OR_CLARIFICATIONS:
    'Medical Questions or Clarifications Involved',
  NEED_TO_SPEAK_WITH_PCP: 'Need to speak with PCP',
  NO_SMARTPHONE_ACCESS: "Don't have access to a smartphone",
  NO_SMARTPHONE_AND_WONT_USE:
    'Does not have a smartphone and will not use someone else’s',
  NO_RING_DEAD_AIR: 'Does not ring / Dead air',
  NOT_INTERESTED: 'Not interested',
  NOT_IN_SERVICE_DISCONNECTED: 'Number no longer in service / disconnected',
  NO_VOICEMAIL: 'Answering machine did not come on',
  NO_VOICEMAIL_OPTION: 'No voice mail option',
  NON_MEMBER_PHONE:
    'Non-member phone number provided (spouse, another family member)',
  OPTED_OUT_BY_GP: 'Opted out via GP',
  OPTED_OUT_BY_SMS: 'Opted out via SMS',
  OTHER: 'Other',
  OUT_OF_COUNTRY_AWAY_FROM_HOME: 'Out of the country / Away from home',
  AWAY_FROM_HOME: 'Away from home for more than 60 days',
  PATIENT_DETAILS_INCORRECT: 'Patient details incorrect',
  PCP_INVOLVEMENT: 'PCP involvement',
  PCP_INVOLVEMENT_QUESTIONS: 'Questions Related to PCP Involvement',
  PCP_DETAILS_VERIFIED: 'PCP details verified',
  PCP_DETAILS_NOT_VERIFIED: "Couldn't verify PCP details",
  PREFER_HEALTH_TESTS:
    'Prefers to health tests with doctor/upcoming appointment with doctor',
  PRIVACY_CONCERNS: 'Privacy concerns',
  RECENT_APPOINTMENT: 'Recent or upcoming appointment/lab work',
  RECENTLY_COMPLETED_ACR: 'Recently completed ACR urine test',
  REFUSED_TRANSLATION_LINE: 'Refused translation line',
  REPORTS_MEDICAL_REASON_FOR_NOT_TESTING:
    'Reports medical reason for not testing',
  SECURITY_CONCERNS: 'Concerned about information security',
  SELECTION_CONCERNS: 'Concerns around the Selection Process',
  TROUBLE_WITH_APP:
    'Not good with tech or apps / Had trouble downloading the app',
  UNABLE_READ_OR_WRITE: 'Unable to read or write',
  UNDER_KIDNEY_SPECIALIST_CARE:
    'Under the care of a specialist who checks kidneys',
  VOICEMAIL_DOES_NOT_MATCH: 'VM greeting name does not match member',
  VOICEMAIL_FULL: 'Answering machine full',
  VOICEMAIL_NOT_NECESSARY: 'VM not necessary',
  VOICEMAIL_NOT_SET_UP: 'VM box not set up',
  WANT_MORE_INFO: 'Want to get more info',
  WONT_CONFIRM_DOB_OVER_PHONE: 'Member will not confirm DOB over phone',
  WRONG_NAME_IN_VOICEMAIL: 'Wrong name in VM greeting',
  WRONG_NUMBER: 'Wrong number',
  SPOKE_TO_NON_MEMBER_ABOUT_CALL_BACK: 'Spoke to non-member about call back',
  SPOKE_TO_NON_MEMBER_CANT_SPEAK_TO_MEMBER:
    'Spoke to non-member: Can not speak to member',
};

export const OUTCOME_HINT = {
  BAD_NUMBER: 'Bad number - No need to use this number again',
  CANNOT_BE_REACHED_BY_PHONE: 'Member cannot be reached by phone',
  DOES_NOT_MEET_INCLUSION_CRITERIA:
    'Members who do not meet the service’s inclusion criteria',
  DO_NOT_CALL: 'Member asks not to call again',
  INTEREST_IN_DOING_TEST: 'Member shows interest in doing the test',
  MEET_INCLUSION_CRITERIA:
    "Members who meet our initial inclusion criteria but don't want to take part",

  NEED_ANOTHER_CONSENT_CALL:
    "Member didn't say if he will do or won't do the test - need another consent call",
  NO_CALL_MADE: 'No call was made - add a note to the activity log',
  REMINDED_TO_DO_TEST: 'Member was reminded to do the test',
  REQUESTED_SCHEDULE: 'Member requested to schedule a call at a later time',
};

export const OUTCOME_OPTION = {
  ALREADY_DONE_ACR: 'alreadyDoneAcr',
  ANSWERING_MACHINE_FULL: 'answeringMachineFull',
  BRING_SAMPLE: 'bringSample',
  BUSY_TONE: 'busyTone',
  CALL_QUEUE_AFFECTED: 'callQueueAffected',
  CALL_QUEUE_NOT_AFFECTED: 'callQueueNotAffected',
  CARE_HOME_RESIDENT: 'careHomeResident',
  PRIVACY_CONCERNS: 'privacyConcerns',
  DONT_SEND_KIT: 'dontSendKit',
  SEND_KIT: 'sendKit',
  DECEASED: 'deceased',
  DISENROLLED: 'Disenrolled',
  DIDNT_LEAVE_VOICEMAIL: 'didntLeaveVoicemail',
  DONT_CALL: 'dontCall',
  DONT_HAVE_DIABETES: 'dontHaveDiabetes',
  DONT_OWN_SMARTPHONE: 'dontOwnSmartphone',
  DONT_NEED_SERVICE: 'dontNeedService',
  DONT_SPEAK_ENGLISH: 'dontSpeakEnglish',
  DONT_SPEAK_LANGUAGES: 'dontSpeakLanguages',
  LACK_OF_CONFIDENCE: 'lackOfConfidence',
  NO_DIAL_TONE: 'noDialTone',
  NO_RESPONSE: 'noResponse',
  NO_VOICEMAIL_OPTION: 'noVoicemailOption',
  NOT_AVAILABLE: 'notAvailable',
  NOT_INTERESTED: 'notInterested',
  NOT_TECHY: 'notTechy',
  NUMBER_NOT_IN_SERVICE: 'numberNotInService',
  OPT_OUT_GP: 'optOutGp',
  OTHER_NOTE: 'otherNote',
  OTHER: 'other',
  WRONG_NUMBER: 'wrongNumber',
  VOICEMAIL_DIDNT_WORK: 'voicemailDidntWork',
  VOICEMAIL_LEFT: 'voicemailLeft',
  VOICEMAIL_NOT_SET: 'voicemailNotSet',
  VOICEMAIL_WRONG_NAME: 'voicemailWrongName',
};

export const OUTCOME_OPTION_LABEL = {
  [OUTCOME_OPTION.ALREADY_DONE_ACR]: 'Has already done an ACR test',
  [OUTCOME_OPTION.ANSWERING_MACHINE_FULL]: 'Answering machine full',
  [OUTCOME_OPTION.BRING_SAMPLE]: 'Would rather bring a sample to surgery',
  [OUTCOME_OPTION.BUSY_TONE]: 'Busy tone',
  [OUTCOME_OPTION.CALL_QUEUE_AFFECTED]: 'Affects the call queue',
  [OUTCOME_OPTION.CALL_QUEUE_NOT_AFFECTED]: 'Does not affect the call queue',
  [OUTCOME_OPTION.CARE_HOME_RESIDENT]: 'Care home resident',
  [OUTCOME_OPTION.PRIVACY_CONCERNS]: 'Privacy concerns',
  [OUTCOME_OPTION.DONT_SEND_KIT]: "Don't send another kit",
  [OUTCOME_OPTION.SEND_KIT]: 'Send a kit',
  [OUTCOME_OPTION.DECEASED]: 'Deceased',
  [OUTCOME_OPTION.DISENROLLED]: 'Disenrolled from plan',
  [OUTCOME_OPTION.DIDNT_LEAVE_VOICEMAIL]: "Didn't leave a voicemail",
  [OUTCOME_OPTION.DONT_CALL]: 'Do not call / hung up',
  [OUTCOME_OPTION.DONT_HAVE_DIABETES]: "Don't have diabetes",
  [OUTCOME_OPTION.DONT_OWN_SMARTPHONE]: "Don't have access to a smartphone",
  [OUTCOME_OPTION.DONT_NEED_SERVICE]:
    'Going regularly to the clinic / just gave a sample / already have an appointment',
  [OUTCOME_OPTION.DONT_SPEAK_ENGLISH]: 'Does not speak English',
  [OUTCOME_OPTION.DONT_SPEAK_LANGUAGES]: 'Do not speak English or Spanish',
  [OUTCOME_OPTION.LACK_OF_CONFIDENCE]:
    'Lack of confidence with apps and self testing tests',
  [OUTCOME_OPTION.NO_DIAL_TONE]: 'Does not ring/dead air',
  [OUTCOME_OPTION.NO_VOICEMAIL_OPTION]: 'No voice mail option',
  [OUTCOME_OPTION.NOT_AVAILABLE]:
    'Out of country / No longer at practice / In hospital',
  [OUTCOME_OPTION.NOT_INTERESTED]: 'Not interested (elaborate in notes)',
  [OUTCOME_OPTION.NOT_TECHY]:
    'Not good with tech / apps / had trouble downloading the app',
  [OUTCOME_OPTION.OPT_OUT_GP]: 'Opted out via GP',
  [OUTCOME_OPTION.OTHER_NOTE]: 'Other (elaborate in note)',
  [OUTCOME_OPTION.OTHER]: 'Other',
  [OUTCOME_OPTION.WRONG_NUMBER]: 'Wrong number',
  [OUTCOME_OPTION.VOICEMAIL_DIDNT_WORK]: 'Answering machine did not come on',
  [OUTCOME_OPTION.VOICEMAIL_LEFT]: 'Voicemail left',
  [OUTCOME_OPTION.VOICEMAIL_NOT_SET]: 'Vm box not set up',
  [OUTCOME_OPTION.VOICEMAIL_WRONG_NAME]: 'Wrong name in vm greeting',
};

export const usStates = [
  { abbreviation: 'AL', name: 'Alabama' },
  { abbreviation: 'AK', name: 'Alaska' },
  { abbreviation: 'AS', name: 'American Samoa' },
  { abbreviation: 'AZ', name: 'Arizona' },
  { abbreviation: 'AR', name: 'Arkansas' },
  { abbreviation: 'CA', name: 'California' },
  { abbreviation: 'CO', name: 'Colorado' },
  { abbreviation: 'CT', name: 'Connecticut' },
  { abbreviation: 'DE', name: 'Delaware' },
  { abbreviation: 'DC', name: 'District of Columbia' },
  { abbreviation: 'FM', name: 'Federated States of Micronesia' },
  { abbreviation: 'FL', name: 'Florida' },
  { abbreviation: 'GA', name: 'Georgia' },
  { abbreviation: 'GU', name: 'Guam' },
  { abbreviation: 'HI', name: 'Hawaii' },
  { abbreviation: 'ID', name: 'Idaho' },
  { abbreviation: 'IL', name: 'Illinois' },
  { abbreviation: 'IN', name: 'Indiana' },
  { abbreviation: 'IA', name: 'Iowa' },
  { abbreviation: 'KS', name: 'Kansas' },
  { abbreviation: 'KY', name: 'Kentucky' },
  { abbreviation: 'LA', name: 'Louisiana' },
  { abbreviation: 'ME', name: 'Maine' },
  { abbreviation: 'MH', name: 'Marshall Islands' },
  { abbreviation: 'MD', name: 'Maryland' },
  { abbreviation: 'MA', name: 'Massachusetts' },
  { abbreviation: 'MI', name: 'Michigan' },
  { abbreviation: 'MN', name: 'Minnesota' },
  { abbreviation: 'MS', name: 'Mississippi' },
  { abbreviation: 'MO', name: 'Missouri' },
  { abbreviation: 'MT', name: 'Montana' },
  { abbreviation: 'NE', name: 'Nebraska' },
  { abbreviation: 'NV', name: 'Nevada' },
  { abbreviation: 'NH', name: 'New Hampshire' },
  { abbreviation: 'NJ', name: 'New Jersey' },
  { abbreviation: 'NM', name: 'New Mexico' },
  { abbreviation: 'NY', name: 'New York' },
  { abbreviation: 'NC', name: 'North Carolina' },
  { abbreviation: 'ND', name: 'North Dakota' },
  { abbreviation: 'MP', name: 'Northern Mariana Islands' },
  { abbreviation: 'OH', name: 'Ohio' },
  { abbreviation: 'OK', name: 'Oklahoma' },
  { abbreviation: 'OR', name: 'Oregon' },
  { abbreviation: 'PW', name: 'Palau' },
  { abbreviation: 'PA', name: 'Pennsylvania' },
  { abbreviation: 'PR', name: 'Puerto Rico' },
  { abbreviation: 'RI', name: 'Rhode Island' },
  { abbreviation: 'SC', name: 'South Carolina' },
  { abbreviation: 'SD', name: 'South Dakota' },
  { abbreviation: 'TN', name: 'Tennessee' },
  { abbreviation: 'TX', name: 'Texas' },
  { abbreviation: 'UT', name: 'Utah' },
  { abbreviation: 'VT', name: 'Vermont' },
  { abbreviation: 'VI', name: 'Virgin Islands' },
  { abbreviation: 'VA', name: 'Virginia' },
  { abbreviation: 'WA', name: 'Washington' },
  { abbreviation: 'WV', name: 'West Virginia' },
  { abbreviation: 'WI', name: 'Wisconsin' },
  { abbreviation: 'WY', name: 'Wyoming' },
];

export const ukStates = [
  { abbreviation: 'I0', name: 'Aberconwy and Colwyn' },
  { abbreviation: 'I1', name: 'Aberdeen City' },
  { abbreviation: 'I2', name: 'Aberdeenshire' },
  { abbreviation: 'I3', name: 'Anglesey' },
  { abbreviation: 'I4', name: 'Angus' },
  { abbreviation: 'I5', name: 'Antrim' },
  { abbreviation: 'I6', name: 'Argyll and Bute' },
  { abbreviation: 'I7', name: 'Armagh' },
  { abbreviation: 'I8', name: 'Avon' },
  { abbreviation: 'I9', name: 'Ayrshire' },
  { abbreviation: 'IB', name: 'Bath and NE Somerset' },
  { abbreviation: 'IC', name: 'Bedfordshire' },
  { abbreviation: 'IE', name: 'Belfast' },
  { abbreviation: 'IF', name: 'Berkshire' },
  { abbreviation: 'IG', name: 'Berwickshire' },
  { abbreviation: 'IH', name: 'BFPO' },
  { abbreviation: 'II', name: 'Blaenau Gwent' },
  { abbreviation: 'IJ', name: 'Buckinghamshire' },
  { abbreviation: 'IK', name: 'Caernarfonshire' },
  { abbreviation: 'IM', name: 'Caerphilly' },
  { abbreviation: 'IO', name: 'Caithness' },
  { abbreviation: 'IP', name: 'Cambridgeshire' },
  { abbreviation: 'IQ', name: 'Cardiff' },
  { abbreviation: 'IR', name: 'Cardiganshire' },
  { abbreviation: 'IS', name: 'Carmarthenshire' },
  { abbreviation: 'IT', name: 'Ceredigion' },
  { abbreviation: 'IU', name: 'Channel Islands' },
  { abbreviation: 'IV', name: 'Cheshire' },
  { abbreviation: 'IW', name: 'City of Bristol' },
  { abbreviation: 'IX', name: 'Clackmannanshire' },
  { abbreviation: 'IY', name: 'Clwyd' },
  { abbreviation: 'IZ', name: 'Conwy' },
  { abbreviation: 'J0', name: 'Cornwall/Scilly' },
  { abbreviation: 'J1', name: 'Cumbria' },
  { abbreviation: 'J2', name: 'Denbighshire' },
  { abbreviation: 'J3', name: 'Derbyshire' },
  { abbreviation: 'J4', name: 'Derry/Londonderry' },
  { abbreviation: 'J5', name: 'Devon' },
  { abbreviation: 'J6', name: 'Dorset' },
  { abbreviation: 'J7', name: 'Down' },
  { abbreviation: 'J8', name: 'Dumfries and Galloway' },
  { abbreviation: 'J9', name: 'Dunbartonshire' },
  { abbreviation: 'JA', name: 'Dundee' },
  { abbreviation: 'JB', name: 'Durham' },
  { abbreviation: 'JC', name: 'Dyfed' },
  { abbreviation: 'JD', name: 'East Ayrshire' },
  { abbreviation: 'JE', name: 'East Dunbartonshire' },
  { abbreviation: 'JF', name: 'East Lothian' },
  { abbreviation: 'JG', name: 'East Renfrewshire' },
  { abbreviation: 'JH', name: 'East Riding Yorkshire' },
  { abbreviation: 'JI', name: 'East Sussex' },
  { abbreviation: 'JJ', name: 'Edinburgh' },
  { abbreviation: 'JK', name: 'England' },
  { abbreviation: 'JL', name: 'Essex' },
  { abbreviation: 'JM', name: 'Falkirk' },
  { abbreviation: 'JN', name: 'Fermanagh' },
  { abbreviation: 'JO', name: 'Fife' },
  { abbreviation: 'JP', name: 'Flintshire' },
  { abbreviation: 'JQ', name: 'Glasgow' },
  { abbreviation: 'JR', name: 'Gloucestershire' },
  { abbreviation: 'JS', name: 'Greater London' },
  { abbreviation: 'JT', name: 'Greater Manchester' },
  { abbreviation: 'JU', name: 'Gwent' },
  { abbreviation: 'JV', name: 'Gwynedd' },
  { abbreviation: 'JW', name: 'Hampshire' },
  { abbreviation: 'JX', name: 'Hartlepool' },
  { abbreviation: 'HAW', name: 'Hereford and Worcester' },
  { abbreviation: 'JY', name: 'Hertfordshire' },
  { abbreviation: 'JZ', name: 'Highlands' },
  { abbreviation: 'K0', name: 'Inverclyde' },
  { abbreviation: 'K1', name: 'Inverness-Shire' },
  { abbreviation: 'K2', name: 'Isle of Man' },
  { abbreviation: 'K3', name: 'Isle of Wight' },
  { abbreviation: 'K4', name: 'Kent' },
  { abbreviation: 'K5', name: 'Kincardinshire' },
  { abbreviation: 'K6', name: 'Kingston Upon Hull' },
  { abbreviation: 'K7', name: 'Kinross-Shire' },
  { abbreviation: 'K8', name: 'Kirklees' },
  { abbreviation: 'K9', name: 'Lanarkshire' },
  { abbreviation: 'KA', name: 'Lancashire' },
  { abbreviation: 'KB', name: 'Leicestershire' },
  { abbreviation: 'KC', name: 'Lincolnshire' },
  { abbreviation: 'KD', name: 'Londonderry' },
  { abbreviation: 'KE', name: 'Merseyside' },
  { abbreviation: 'KF', name: 'Merthyr Tydfil' },
  { abbreviation: 'KG', name: 'Mid Glamorgan' },
  { abbreviation: 'KI', name: 'Mid Lothian' },
  { abbreviation: 'KH', name: 'Middlesex' },
  { abbreviation: 'KJ', name: 'Monmouthshire' },
  { abbreviation: 'KK', name: 'Moray' },
  { abbreviation: 'KL', name: 'Neath & Port Talbot' },
  { abbreviation: 'KM', name: 'Newport' },
  { abbreviation: 'KN', name: 'Norfolk' },
  { abbreviation: 'KP', name: 'North Ayrshire' },
  { abbreviation: 'KQ', name: 'North East Lincolnshire' },
  { abbreviation: 'KR', name: 'North Lanarkshire' },
  { abbreviation: 'KT', name: 'North Lincolnshire' },
  { abbreviation: 'KU', name: 'North Somerset' },
  { abbreviation: 'KV', name: 'North Yorkshire' },
  { abbreviation: 'KO', name: 'Northamptonshire' },
  { abbreviation: 'KW', name: 'Northern Ireland' },
  { abbreviation: 'KX', name: 'Northumberland' },
  { abbreviation: 'KZ', name: 'Nottinghamshire' },
  { abbreviation: 'L0', name: 'Orkney and Shetland Isles' },
  { abbreviation: 'L1', name: 'Oxfordshire' },
  { abbreviation: 'L2', name: 'Pembrokeshire' },
  { abbreviation: 'L3', name: 'Perth and Kinross' },
  { abbreviation: 'L4', name: 'Powys' },
  { abbreviation: 'L5', name: 'Redcar and Cleveland' },
  { abbreviation: 'L6', name: 'Renfrewshire' },
  { abbreviation: 'L7', name: 'Rhonda Cynon Taff' },
  { abbreviation: 'L8', name: 'Rutland' },
  { abbreviation: 'L9', name: 'Scottish Borders' },
  { abbreviation: 'LB', name: 'Shetland' },
  { abbreviation: 'LC', name: 'Shropshire' },
  { abbreviation: 'LD', name: 'Somerset' },
  { abbreviation: 'LE', name: 'South Ayrshire' },
  { abbreviation: 'LF', name: 'South Glamorgan' },
  { abbreviation: 'LG', name: 'South Gloucesteshire' },
  { abbreviation: 'LH', name: 'South Lanarkshire' },
  { abbreviation: 'LI', name: 'South Yorkshire' },
  { abbreviation: 'LJ', name: 'Staffordshire' },
  { abbreviation: 'LK', name: 'Stirling' },
  { abbreviation: 'LL', name: 'Stockton On Tees' },
  { abbreviation: 'LM', name: 'Suffolk' },
  { abbreviation: 'LN', name: 'Surrey' },
  { abbreviation: 'LO', name: 'Swansea' },
  { abbreviation: 'LP', name: 'Torfaen' },
  { abbreviation: 'LQ', name: 'Tyne and Wear' },
  { abbreviation: 'LR', name: 'Tyrone' },
  { abbreviation: 'LS', name: 'Vale Of Glamorgan' },
  { abbreviation: 'LT', name: 'Wales' },
  { abbreviation: 'LU', name: 'Warwickshire' },
  { abbreviation: 'LV', name: 'West Berkshire' },
  { abbreviation: 'LW', name: 'West Dunbartonshire' },
  { abbreviation: 'LX', name: 'West Glamorgan' },
  { abbreviation: 'LY', name: 'West Lothian' },
  { abbreviation: 'LZ', name: 'West Midlands' },
  { abbreviation: 'M0', name: 'West Sussex' },
  { abbreviation: 'M1', name: 'West Yorkshire' },
  { abbreviation: 'M2', name: 'Western Isles' },
  { abbreviation: 'M3', name: 'Wiltshire' },
  { abbreviation: 'M4', name: 'Wirral' },
  { abbreviation: 'M5', name: 'Worcestershire' },
  { abbreviation: 'M6', name: 'Wrexham' },
  { abbreviation: 'M7', name: 'York' },
];

export const CommunicationPreferenceStatus = {
  ALLOWED: 'allowed',
  DENIED: 'denied',
};

export const CommunicationPreferenceStatusLabel = {
  [CommunicationPreferenceStatus.ALLOWED]: 'Allowed',
  [CommunicationPreferenceStatus.DENIED]: 'Denied',
};

export const CommunicationPreference = {
  CALL: 'call',
  EMAIL: 'email',
  LETTER: 'letter',
  SMS: 'sms',
};

export const CommunicationPreferenceLabels = {
  [CommunicationPreference.CALL]: 'Calls',
  [CommunicationPreference.EMAIL]: 'Emails',
  [CommunicationPreference.LETTER]: 'Letters',
  [CommunicationPreference.SMS]: 'SMS',
};

export const TrackScreenType = {
  CALLS: 'Calls View',
  EDIT_PATIENT: 'Edit Patient Info',
  EDIT_PCP: 'Edit PCP Info',
  PATIENT: 'Patient Page',
  PATIENT_LIST: 'Patient List',
  PCP_CENTER: 'PCP Center',
  SCRIPT: 'Script',
};

export const PatientInfoField = {
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  CITY: 'city',
  COUNTRY: 'country',
  FIRST_NAME: 'firstName',
  HEALTH_ID: 'healthId',
  LANDLINE_NUMBER: 'landlineNumber',
  LAST_NAME: 'lastName',
  MOBILE_NUMBER: 'mobileNumber',
  POSTAL_CODE: 'postalCode',
  STATE_OR_PROVINCE: 'stateOrProvince',
  ZIP_CODE: 'zipCode',
};

export const PatientInfoFieldLabels = {
  [PatientInfoField.ADDRESS_LINE_1]: 'Address line 1',
  [PatientInfoField.ADDRESS_LINE_2]: 'Address line 2',
  [PatientInfoField.CITY]: 'City',
  [PatientInfoField.COUNTRY]: 'Country',
  [PatientInfoField.FIRST_NAME]: 'First name',
  [PatientInfoField.HEALTH_ID]: 'ID',
  [PatientInfoField.LANDLINE_NUMBER]: 'Home phone number',
  [PatientInfoField.LAST_NAME]: 'Last name',
  [PatientInfoField.MOBILE_NUMBER]: 'Mobile phone number',
  [PatientInfoField.POSTAL_CODE]: 'Postal code',
  [PatientInfoField.STATE_OR_PROVINCE]: 'State',
  [PatientInfoField.ZIP_CODE]: 'ZIP code',
};

export const LanguageCodes = {
  BEN: 'ben',
  CZE: 'cze',
  DEU: 'deu',
  ENG: 'eng',
  FRA: 'fra',
  GRE: 'gre',
  GUJ: 'guj',
  HIN: 'hin',
  IND: 'ind',
  JPN: 'jpn',
  KOR: 'kor',
  CMN: 'cmn',
  MAR: 'mar',
  POL: 'pol',
  POR: 'por',
  RUS: 'rus',
  SPA: 'spa',
  SVK: 'svk',
  ARB: 'arb',
  TAM: 'tam',
  TEL: 'tel',
  TUR: 'tur',
  URD: 'urd',
  WUU: 'wuu',
  YUE: 'yue',
  UNSPECIFIED: 'unspecified',
};

export const LanguageLabels = {
  [LanguageCodes.BEN]: 'Bengali',
  [LanguageCodes.CZE]: 'Czech',
  [LanguageCodes.DEU]: 'German',
  [LanguageCodes.ENG]: 'English',
  [LanguageCodes.FRA]: 'French',
  [LanguageCodes.GRE]: 'Greek',
  [LanguageCodes.GUJ]: 'Gujarati',
  [LanguageCodes.HIN]: 'Hindi',
  [LanguageCodes.IND]: 'Indonesian',
  [LanguageCodes.JPN]: 'Japanese',
  [LanguageCodes.KOR]: 'Korean',
  [LanguageCodes.CMN]: 'Mandarin Chinese',
  [LanguageCodes.MAR]: 'Marathi',
  [LanguageCodes.POL]: 'Polish',
  [LanguageCodes.POR]: 'Portuguese',
  [LanguageCodes.RUS]: 'Russian',
  [LanguageCodes.SPA]: 'Spanish',
  [LanguageCodes.SVK]: 'Slovak',
  [LanguageCodes.ARB]: 'Standard Arabic',
  [LanguageCodes.TAM]: 'Tamil',
  [LanguageCodes.TEL]: 'Telugu',
  [LanguageCodes.TUR]: 'Turkish',
  [LanguageCodes.URD]: 'Urdu',
  [LanguageCodes.WUU]: 'Wu Chinese',
  [LanguageCodes.YUE]: 'Yue Chinese',
  [LanguageCodes.UNSPECIFIED]: 'Unspecified',
};

export const languages = [
  { label: LanguageLabels[LanguageCodes.BEN], code: LanguageCodes.BEN },
  { label: LanguageLabels[LanguageCodes.CZE], code: LanguageCodes.CZE },
  { label: LanguageLabels[LanguageCodes.DEU], code: LanguageCodes.DEU },
  { label: LanguageLabels[LanguageCodes.ENG], code: LanguageCodes.ENG },
  { label: LanguageLabels[LanguageCodes.FRA], code: LanguageCodes.FRA },
  { label: LanguageLabels[LanguageCodes.GRE], code: LanguageCodes.GRE },
  { label: LanguageLabels[LanguageCodes.GUJ], code: LanguageCodes.GUJ },
  { label: LanguageLabels[LanguageCodes.HIN], code: LanguageCodes.HIN },
  { label: LanguageLabels[LanguageCodes.IND], code: LanguageCodes.IND },
  { label: LanguageLabels[LanguageCodes.JPN], code: LanguageCodes.JPN },
  { label: LanguageLabels[LanguageCodes.SVK], code: LanguageCodes.SVK },
  { label: LanguageLabels[LanguageCodes.POL], code: LanguageCodes.POL },
  { label: LanguageLabels[LanguageCodes.KOR], code: LanguageCodes.KOR },
  { label: LanguageLabels[LanguageCodes.CMN], code: LanguageCodes.CMN },
  { label: LanguageLabels[LanguageCodes.MAR], code: LanguageCodes.MAR },
  {
    label: LanguageLabels[LanguageCodes.POR],
    code: LanguageCodes.POR,
    common: true,
  },
  {
    label: LanguageLabels[LanguageCodes.RUS],
    code: LanguageCodes.RUS,
    common: true,
  },
  {
    label: LanguageLabels[LanguageCodes.SPA],
    code: LanguageCodes.SPA,
    common: true,
  },
  { label: LanguageLabels[LanguageCodes.ARB], code: LanguageCodes.ARB },
  { label: LanguageLabels[LanguageCodes.TAM], code: LanguageCodes.TAM },
  { label: LanguageLabels[LanguageCodes.TEL], code: LanguageCodes.TEL },
  { label: LanguageLabels[LanguageCodes.TUR], code: LanguageCodes.TUR },
  { label: LanguageLabels[LanguageCodes.URD], code: LanguageCodes.URD },
  { label: LanguageLabels[LanguageCodes.WUU], code: LanguageCodes.WUU },
  { label: LanguageLabels[LanguageCodes.YUE], code: LanguageCodes.YUE },
  {
    label: LanguageLabels[LanguageCodes.UNSPECIFIED],
    code: LanguageCodes.UNSPECIFIED,
  },
];

export const DirectionType = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
};

export const scriptIdToCallType = {
  [Scripts.CONSENT]: 'consent',
  [Scripts.CONSENT_CALL_FIRST]: 'consent',
  [Scripts.CONSENT_KIT_FIRST]: 'consent',
  [Scripts.REMINDER]: 'reminder',
  [Scripts.SUPPORT]: 'support',
  [Scripts.PCP_CONFIRMATION]: 'support',
  [Scripts.TEST_FAILED]: 'support',
};

export const filterLabels = {
  language: LanguageLabels,
  queueState: QUEUE_STATE_LABELS,
};

export const FilterGroupLabels = {
  clinicName: 'Clinic Name',
  cohortName: 'Cohort Name',
  language: 'Language',
};

export const LimiterStatus = {
  DELAYED: 'delayed',
  REJECTED: 'rejected',
};

export const NoteMethods = {
  APP_REVIEW: 'appReview',
  CALL: 'call',
  CHAT: 'chat',
  COMPLAINTS: 'complaints',
  EMAIL: 'email',
  FEEDBACK: 'feedback',
  KIT: 'kit',
  OTHER: 'other',
  SMS: 'sms',
};

export const NoteMethodLabels = {
  [NoteMethods.APP_REVIEW]: 'App review',
  [NoteMethods.CALL]: 'Call',
  [NoteMethods.CHAT]: 'Chat',
  [NoteMethods.COMPLAINTS]: 'Complaints',
  [NoteMethods.EMAIL]: 'Email',
  [NoteMethods.FEEDBACK]: 'Feedback',
  [NoteMethods.KIT]: 'Kit',
  [NoteMethods.OTHER]: 'Other',
  [NoteMethods.SMS]: 'SMS',
};

export const PATIENT_CONDITION_LABELS = {
  diabetes: 'Diabetes',
  hypertension: 'Hypertension',
};

export const GenderLabels = {
  female: 'Female',
  male: 'Male',
  other: 'Other',
};

export const US_TIMEZONES = {
  AMERICA_LOS_ANGELES: 'America/Los_Angeles',
  AMERICA_DENVER: 'America/Denver',
  AMERICA_CHICAGO: 'America/Chicago',
  AMERICA_NEW_YORK: 'America/New_York',
  AMERICA_ANCHORAGE: 'America/Anchorage',
  AMERICA_PHOENIX: 'America/Phoenix',
  AMERICA_INDIANAPOLIS: 'America/Indianapolis',
  AMERICA_DETROIT: 'America/Detroit',
  PACIFIC_HONOLULU: 'Pacific/Honolulu',
  AMERICA_BOISE: 'America/Boise',
  AMERICA_PUERTO_RICO: 'America/Puerto_Rico',
};

export const US_AERIAL_TIMEZONES = {
  PACIFIC_TIME: 'US Pacific',
  MOUNTAIN_TIME: 'US Mountain',
  CENTRAL_TIME: 'US Central',
  EASTERN_TIME: 'US Eastern',
  ALASKA_TIME: 'US Alaska',
  HAWAII_TIME: 'US Hawaii',
  PUERTO_RICO: 'US Puerto Rico',
  SAMOA: 'US Samoa',
  CHAMORRO: 'US Chamorro',
  ATLANTIC_TIME: 'US Atlantic',
};

export const US_TIMEZONES_TO_AERIAL_LABELS = {
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_ANCHORAGE]: US_AERIAL_TIMEZONES.ALASKA_TIME,
  [US_TIMEZONES.AMERICA_PHOENIX]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_LOS_ANGELES]: US_AERIAL_TIMEZONES.PACIFIC_TIME,
  [US_TIMEZONES.AMERICA_DENVER]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.PACIFIC_HONOLULU]: US_AERIAL_TIMEZONES.HAWAII_TIME,
  [US_TIMEZONES.AMERICA_BOISE]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_INDIANAPOLIS]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_DETROIT]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_DENVER]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_LOS_ANGELES]: US_AERIAL_TIMEZONES.PACIFIC_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_DENVER]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_LOS_ANGELES]: US_AERIAL_TIMEZONES.PACIFIC_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_PUERTO_RICO]: US_AERIAL_TIMEZONES.ATLANTIC_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_DENVER]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_LOS_ANGELES]: US_AERIAL_TIMEZONES.PACIFIC_TIME,
  [US_TIMEZONES.AMERICA_NEW_YORK]: US_AERIAL_TIMEZONES.EASTERN_TIME,
  [US_TIMEZONES.AMERICA_CHICAGO]: US_AERIAL_TIMEZONES.CENTRAL_TIME,
  [US_TIMEZONES.AMERICA_DENVER]: US_AERIAL_TIMEZONES.MOUNTAIN_TIME,
};

export const PCP_CONFIRMATION_STATUS = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
};

export const formFieldsMap = {
  patient: {
    address: {
      addressLine1: {
        name: 'addressLine1',
        errorPaths: ['addressLine1', 'message'],
        css: {
          colSpan: 6,
        },
      },
      addressLine2: {
        name: 'addressLine2',
        errorPaths: ['addressLine2', 'message'],
        css: {
          colSpan: 6,
        },
      },
      city: {
        name: 'city',
        errorPaths: ['city', 'message'],
        css: {
          colSpan: 3,
        },
      },
      stateOrProvince: {
        name: 'stateOrProvince',
        errorPaths: ['stateOrProvince', 'message'],
        css: {
          colSpan: 3,
        },
      },
      postalCode: {
        name: 'postalCode',
        errorPaths: ['postalCode', 'message'],
        css: {
          colSpan: 3,
        },
      },
      country: {
        name: 'country',
        errorPaths: ['country', 'message'],
        css: {
          colSpan: 3,
        },
      },
    },
  },
  pcp: {
    address: {
      addressLine1: {
        name: 'address.addressLine1',
        errorPaths: ['address', 'addressLine1', 'message'],
        css: {
          colSpan: 6,
        },
      },
      addressLine2: {
        name: 'address.addressLine2',
        errorPaths: ['address', 'addressLine2', 'message'],
        css: {
          colSpan: 6,
        },
      },
      city: {
        name: 'address.city',
        errorPaths: ['address', 'city', 'message'],
        css: {
          colSpan: 3,
        },
      },
      stateOrProvince: {
        name: 'address.stateOrProvince',
        errorPaths: ['address', 'stateOrProvince', 'message'],
        css: {
          colSpan: 3,
        },
      },
      postalCode: {
        name: 'address.zipCode',
        errorPaths: ['address', 'zipCode', 'message'],
        css: {
          colSpan: 3,
        },
      },
      country: {
        name: 'address.country',
        errorPaths: ['address', 'country', 'message'],
        css: {
          colSpan: 3,
        },
      },
    },
  },
};

export const PCP_CONFIRMATION_FIELDS_BY_TYPE = {
  npi: {
    textInputProps: {
      name: 'npi',
      label: 'NPI number',
      type: 'number',
    },
    confirmationCheckboxProps: {
      name: 'npiConfirmationStatus',
      label: 'NPI confirmed',
    },
  },
  faxNumber: {
    textInputProps: {
      name: 'faxNumber',
      label: 'Fax number',
    },
    confirmationCheckboxProps: {
      name: 'faxConfirmationStatus',
      label: 'Fax confirmed',
    },
  },
  fullName: {
    textInputProps: {
      name: 'fullName',
      label: 'Full name',
    },
    confirmationCheckboxProps: {
      name: 'confirmationStatus',
      label: 'PCP confirmed',
    },
  },
};
