import PropTypes from 'prop-types';

import { cx } from '@emotion/css';
import { css } from '@emotion/react';

import { toRem } from '../../../helpers';

import {
  borderRadius,
  colors,
  fontSizes,
  shadows,
  spacing,
} from '../../../theme';

const controlCss = ({ isDisabled, isErrored, isFocused }) => css`
  display: flex;
  align-items: center;
  border: 1px solid;
  border-color: ${colors.gray600};
  border-radius: ${borderRadius.lg};
  height: ${toRem(40)};
  padding: ${spacing.space1} ${spacing.space3};
  font-size: ${fontSizes.body2};
  width: 100%;
  background-color: ${colors.white};
  color: ${colors.black};

  // Regular state
  ${!isDisabled &&
  css`
    :hover {
      border-color: ${colors.primary};
    }
  `}

  // Error state
  ${isErrored &&
  !isDisabled &&
  css`
    border-color: ${colors.danger};

    :hover {
      border-color: ${colors.danger600};
    }
  `}

  // Focus state
  ${isFocused &&
  css`
    border-color: ${isErrored ? colors.danger600 : colors.gray600};
    box-shadow: ${isErrored
      ? `0 0 0 ${toRem(3)} ${colors.danger300}`
      : shadows.focusRing};
  `}

  // Disabled state
  ${isDisabled &&
  css`
    background-color: ${colors.gray200};
    cursor: not-allowed;
  `}
`;

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    isFocused,
    selectProps: { isDisabled, isErrored },
  } = props;

  return (
    <div
      className={cx('HioSelect__control', {
        'HioSelect__control--is-disabled': isDisabled,
        'HioSelect__control--is-errored': isErrored,
        'HioSelect__control--is-focused': isFocused,
      })}
      css={controlCss({ isDisabled, isErrored, isFocused })}
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </div>
  );
}

Control.propTypes = {
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isFocused: PropTypes.bool.isRequired,
  selectProps: PropTypes.shape({
    isDisabled: PropTypes.bool,
    isErrored: PropTypes.bool,
  }).isRequired,
};

export default Control;
