import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { cx } from '@emotion/css';
import { toRem } from '../../helpers';
import { colors, spacing } from '../../theme';
import Icon from '../Icon/Icon';

export const itemCss = (props) => css`
  height: ${toRem(40)};
  display: flex;
  align-items: center;
  padding-inline: ${spacing.space3};
  column-gap: ${spacing.space4};

  ${props.isDisabled &&
  css`
    opacity: 0.5;
  `}

  :hover {
    background-color: ${colors.blueGray200};
    outline: 0;
    cursor: pointer;
  }

  > span {
    flex-grow: 1;
  }
`;

const layoutCss = css`
  display: flex;
  align-items: center;
  column-gap: ${spacing.space4};
`;

const Item = forwardRef(
  (
    {
      children,
      className,
      endIcon,
      isDisabled = false,
      onClick,
      startIcon,
      ...restProps
    },
    ref,
  ) => (
    <DropdownMenuPrimitive.Item
      css={itemCss({ isDisabled })}
      className={cx(className, 'HioDropdown__item', {
        'HioDropdown__item--disabled': isDisabled,
      })}
      disabled={isDisabled}
      onSelect={onClick}
      ref={ref}
      {...restProps}
    >
      <div css={layoutCss}>
        {startIcon && <Icon icon={startIcon} size="sm" />}
        <span>{children}</span>
        {endIcon && <Icon icon={endIcon} size="sm" />}
      </div>
    </DropdownMenuPrimitive.Item>
  ),
);

Item.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  endIcon: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.string,
};

Item.displayName = 'Dropdown.Item';

export default Item;
