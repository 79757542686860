export const borderRadius = {
  sm: 'var(--border-radius-sm)',
  lg: 'var(--border-radius-lg)',
};

/**
 * Gallery of colors used by Theme.
 */
export const colors = {
  // Primary pallet
  black: 'var(--black)',
  primary: 'var(--primary)',
  white: 'var(--white)',

  // Secondary pallet
  danger: 'var(--danger)',
  success: 'var(--success)',
  warning: 'var(--warning)',

  // Semantics
  text: 'var(--text)',
  overlay: 'var(--overlay)',

  // Blue
  blue100: 'var(--blue-100)',
  blue200: 'var(--blue-200)',
  blue300: 'var(--blue-300)',
  blue400: 'var(--blue-400)',
  blue500: 'var(--blue-500)',
  blue600: 'var(--blue-600)',
  blue700: 'var(--blue-700)',

  // Blue gray
  blueGray100: 'var(--blue-gray-100)',
  blueGray200: 'var(--blue-gray-200)',
  blueGray300: 'var(--blue-gray-300)',
  blueGray400: 'var(--blue-gray-400)',
  blueGray500: 'var(--blue-gray-500)',
  blueGray600: 'var(--blue-gray-600)',
  blueGray700: 'var(--blue-gray-700)',

  // Brown
  brown100: 'var(--brown-100)',
  brown200: 'var(--brown-200)',
  brown300: 'var(--brown-300)',
  brown400: 'var(--brown-400)',
  brown500: 'var(--brown-500)',
  brown600: 'var(--brown-600)',
  brown700: 'var(--brown-700)',

  // Gray
  gray100: 'var(--gray-100)',
  gray200: 'var(--gray-200)',
  gray300: 'var(--gray-300)',
  gray400: 'var(--gray-400)',
  gray500: 'var(--gray-500)',
  gray600: 'var(--gray-600)',
  gray700: 'var(--gray-700)',

  // Green
  green100: 'var(--green-100)',
  green200: 'var(--green-200)',
  green300: 'var(--green-300)',
  green400: 'var(--green-400)',
  green500: 'var(--green-500)',
  green600: 'var(--green-600)',
  green700: 'var(--green-700)',

  // Indigo
  indigo100: 'var(--indigo-100)',
  indigo200: 'var(--indigo-200)',
  indigo300: 'var(--indigo-300)',
  indigo400: 'var(--indigo-400)',
  indigo500: 'var(--indigo-500)',
  indigo600: 'var(--indigo-600)',
  indigo700: 'var(--indigo-700)',

  // Orange
  orange100: 'var(--orange-100)',
  orange200: 'var(--orange-200)',
  orange300: 'var(--orange-300)',
  orange400: 'var(--orange-400)',
  orange500: 'var(--orange-500)',
  orange600: 'var(--orange-600)',
  orange700: 'var(--orange-700)',

  // Red
  red100: 'var(--red-100)',
  red200: 'var(--red-200)',
  red300: 'var(--red-300)',
  red400: 'var(--red-400)',
  red500: 'var(--red-500)',
  red600: 'var(--red-600)',
  red700: 'var(--red-700)',

  // Red gray
  redGray100: 'var(--red-gray-100)',
  redGray200: 'var(--red-gray-200)',
  redGray300: 'var(--red-gray-300)',
  redGray400: 'var(--red-gray-400)',
  redGray500: 'var(--red-gray-500)',
  redGray600: 'var(--red-gray-600)',
  redGray700: 'var(--red-gray-700)',

  // Salmon
  salmon100: 'var(--salmon-100)',
  salmon200: 'var(--salmon-200)',
  salmon300: 'var(--salmon-300)',
  salmon400: 'var(--salmon-400)',
  salmon500: 'var(--salmon-500)',
  salmon600: 'var(--salmon-600)',
  salmon700: 'var(--salmon-700)',

  // Sky blue
  skyBlue100: 'var(--sky-blue-100)',
  skyBlue200: 'var(--sky-blue-200)',
  skyBlue300: 'var(--sky-blue-300)',
  skyBlue400: 'var(--sky-blue-400)',
  skyBlue500: 'var(--sky-blue-500)',
  skyBlue600: 'var(--sky-blue-600)',
  skyBlue700: 'var(--sky-blue-700)',

  // Turquoise
  turquoise100: 'var(--turquoise-100)',
  turquoise200: 'var(--turquoise-200)',
  turquoise300: 'var(--turquoise-300)',
  turquoise400: 'var(--turquoise-400)',
  turquoise500: 'var(--turquoise-500)',
  turquoise600: 'var(--turquoise-600)',
  turquoise700: 'var(--turquoise-700)',

  // Yellow
  yellow100: 'var(--yellow-100)',
  yellow200: 'var(--yellow-200)',
  yellow300: 'var(--yellow-300)',
  yellow400: 'var(--yellow-400)',
  yellow500: 'var(--yellow-500)',
  yellow600: 'var(--yellow-600)',
  yellow700: 'var(--yellow-700)',

  // Danger
  danger100: 'var(--danger-100)',
  danger200: 'var(--danger-200)',
  danger300: 'var(--danger-300)',
  danger400: 'var(--danger-400)',
  danger500: 'var(--danger-500)',
  danger600: 'var(--danger-600)',
  danger700: 'var(--danger-700)',

  // Primary
  primary100: 'var(--primary-100)',
  primary200: 'var(--primary-200)',
  primary300: 'var(--primary-300)',
  primary400: 'var(--primary-400)',
  primary500: 'var(--primary-500)',
  primary600: 'var(--primary-600)',
  primary700: 'var(--primary-700)',

  // Success
  success100: 'var(--success-100)',
  success200: 'var(--success-200)',
  success300: 'var(--success-300)',
  success400: 'var(--success-400)',
  success500: 'var(--success-500)',
  success600: 'var(--success-600)',
  success700: 'var(--success-700)',

  // Warning
  warning100: 'var(--warning-100)',
  warning200: 'var(--warning-200)',
  warning300: 'var(--warning-300)',
  warning400: 'var(--warning-400)',
  warning500: 'var(--warning-500)',
  warning600: 'var(--warning-600)',
  warning700: 'var(--warning-700)',
};

export const elevations = {
  elevation100: 'var(--elevation-100)',
  elevation200: 'var(--elevation-200)',
};

export const fontSizes = {
  caption: 'var(--font-size-caption)',
  body1: 'var(--font-size-body1)',
  body2: 'var(--font-size-body2)',
  h1: 'var(--font-size-h1)',
  h2: 'var(--font-size-h2)',
  h3: 'var(--font-size-h3)',
  h4: 'var(--font-size-h4)',
  h5: 'var(--font-size-h5)',
  h6: 'var(--font-size-h6)',
  subtitle1: 'var(--font-size-subtitle1)',
  subtitle2: 'var(--font-size-subtitle2)',
};

export const spacing = {
  space1: 'var(--spacing-1)',
  space2: 'var(--spacing-2)',
  space3: 'var(--spacing-3)',
  space4: 'var(--spacing-4)',
  space5: 'var(--spacing-5)',
  space6: 'var(--spacing-6)',
  space7: 'var(--spacing-7)',
  space8: 'var(--spacing-8)',
  space9: 'var(--spacing-9)',
  space10: 'var(--spacing-10)',
  space11: 'var(--spacing-11)',
};

export const shadows = {
  elevation: 'var(--shadow-elevation)',
  focusRing: 'var(--shadow-focus-ring)',
};

export const zIndex = {
  top: 'var(--z-index-top)',
  bottom: 'var(--z-index-bottom)',
  default: 'var(--z-index-default)',
  focus: 'var(--z-index-focus)',
  overlay: 'var(--z-index-overlay)',
  modal: 'var(--z-index-modal)',
  dropdown: 'var(--z-index-dropdown)',
  toast: 'var(--z-index-toast)',
  tooltip: 'var(--z-index-tooltip)',
};
