import { useNavigate } from 'react-router-dom';

import { AuthProvider, useHealthyAuth } from '@packages/healthy-auth';

import AuthenticatedApp from 'containers/App/AuthenticatedAppLoadable';
import UnauthenticatedApp from 'containers/App/UnauthenticatedAppLoadable';

function AppAuthProvider() {
  const navigate = useNavigate();
  const { forceLogout } = useHealthyAuth();

  function appLogout() {
    forceLogout({ withRedirectToRoot: false });
    navigate('/');
  }

  return (
    <AuthProvider
      apiUrls={[process.env.HEART_API_URL, process.env.HEART_GATEWAY_URL]}
      AuthenticatedApp={AuthenticatedApp}
      UnauthenticatedApp={UnauthenticatedApp}
      clientId={process.env.USER_MANAGEMENT_CLIENT_ID}
      redirectUri={window.location.origin}
      options={{
        userClaimsUrl: `${process.env.HEART_API_URL}userClaims`,
      }}
      enableDeepLinking={false}
      appLogout={appLogout}
    />
  );
}

export default AppAuthProvider;
