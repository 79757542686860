import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import omit from 'lodash/omit';

import history from './services/history';
import { isIntegrationTest } from './services/integrationTestUtils';

// As cookies will also be visible to all subdomains We're suffixing the domain to the cookie key name
// See here: https://github.com/js-cookie/js-cookie#domain
const domain = window.location.hostname;
const REDIRECT_URL_KEY = 'redirectUrl';
const EXTERNAL_PID_KEY = 'externalPID';
export const EXTERNAL_PROVIDERS = {
  EPIC: 'epic',
  MCB: 'mcb',
  CERNER: 'cerner',
  DR_CHRONO: 'dr_chrono',
};

Cookies.withAttributes({
  expires: 10,
});

export function getTokenFromStorage() {
  const token =
    Cookies.get(`token-${domain}`, { domain }) ??
    Cookies.get(`token-${domain}-legacy`, { domain }) ??
    null;
  return token;
}

export function saveTokenToStorage(token) {
  // Make token secure but only in environments where we use HTTPS
  const isBuildButNotCI =
    process.env.NODE_ENV === 'production' && !isIntegrationTest();

  Cookies.set(`token-${domain}`, token, {
    domain,
    ...(isBuildButNotCI && { sameSite: 'none', secure: true }),
  });
  /* Fallback token for Chrome browsers 51 - 67
  https://www.chromium.org/updates/same-site/incompatible-clients
  https://github.com/OwnHealthIL/healthy-web/pull/3728 */
  Cookies.set(`token-${domain}-legacy`, token, {
    domain,
    ...(isBuildButNotCI && { secure: true }),
  });
}

export function clearTokenFromStorage() {
  Cookies.remove(`token-${domain}`, { domain });
  Cookies.remove(`token-${domain}-legacy`, { domain });
}

export function getPathnameFromStorage() {
  return sessionStorage.getItem(REDIRECT_URL_KEY);
}

export function savePathnameToStorage(url) {
  sessionStorage.setItem(REDIRECT_URL_KEY, url);
}

export function clearPathnameFromStorage() {
  sessionStorage.removeItem(REDIRECT_URL_KEY);
}

export function getExternalPatientIdFromStorage() {
  return sessionStorage.getItem(EXTERNAL_PID_KEY);
}

export function saveExternalPatientIdToStorage(id) {
  sessionStorage.setItem(EXTERNAL_PID_KEY, id);
}

export function clearExternalPatientIdFromStorage() {
  sessionStorage.removeItem(EXTERNAL_PID_KEY);
}

// Compare incoming and existing tokens. Act according:
// - candidate with no existing (first login) -> save the candidate instead
// - Same -> Bail out (we don't need to do anything else)
// - Different -> Clear the existing token from LS and save the candidate instead
export function doTokenCompareAndReplace(candidateToken) {
  const existingToken = getTokenFromStorage();

  if (!existingToken && candidateToken) {
    saveTokenToStorage(candidateToken);
  }

  if (existingToken && candidateToken) {
    if (existingToken === candidateToken) return;

    clearTokenFromStorage();
    saveTokenToStorage(candidateToken);
  }
}

/**
 * Dispatch healthy auth custom "refreshToken" event which updates an auth token extracted
 * from a custom "x-healthy-token" response header on each request.
 *
 * @param {object} headers - Response headers.
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Response/headers
 */
export function handleRefreshToken(headers) {
  const existingToken = getTokenFromStorage();
  // Don't refresh the token if the user has logged out
  if (!existingToken) return;

  const refreshToken =
    headers instanceof Headers
      ? headers.get('x-healthy-token')
      : headers['x-healthy-token'];

  // Trigger CustomEvent to share new token with AuthProvider (only in current tab)
  const refreshTokenEvent = new CustomEvent('refreshToken', {
    detail: { refreshToken },
  });
  window.dispatchEvent(refreshTokenEvent);
}

/**
 * Append an Authorization custom header with the auth token.
 *
 * @see
 * [Fetch API: Headers](https://developer.mozilla.org/en-US/docs/Web/API/Headers)
 *
 * @returns {object} Headers with authentication
 */
export function getHeadersWithAuthToken() {
  const token = getTokenFromStorage();

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);

  return headers;
}

/**
 * Remove auth related query param from URL & maintain other query params if exist
 * @param {object} parsedQs - Query params from URL
 * @param {string?} parsedQs.error - Error if exist
 * @param {string?} parsedQs.token - Token from UM
 * @param {string?} parsedQs.singleUseToken - Disposable Token to exchange for a fixed auth token
 * @param {string?} parsedQs.region - Current user region. Used when config includes userClaimsMultiRegion
 * @param {string?} parsedQs.ap - Auth provider name when using other provider then Auth0
 * @param {string?} parsedQs.iss - Included when ap === 'epic
 * @param {string?} parsedQs.launch - Included when ap === 'epic
 */
export function removeAuthRelatedQueryString(parsedQs) {
  const authKeys = [
    'error',
    'token',
    'singleUseToken',
    'region',
    'ap',
    'iss',
    'launch',
  ];
  const omittedQs = omit(parsedQs, authKeys);
  history.replace({
    search: queryString.stringify(omittedQs),
  });
}

export function getTokenClaims(token) {
  const { email, fullName, tags } = jwtDecode(token);
  return { email, fullName, tags };
}

export function getTokenExpireTime(token) {
  const { exp } = jwtDecode(token);
  // Received exp time is in seconds
  return exp * 1000;
}

/**
 * Remove user-claims from local-storage.
 *
 * This is being done in order to bypass a bug in which apps are missing
 * additional user-claims when logging in different tabs.
 *
 * Ideally, we won't place it in local-storage.
 *
 * @see
 * - https://github.com/OwnHealthIL/healthy-web/issues/4881
 * - https://github.com/OwnHealthIL/healthy-web/issues/4471
 */
function clearUserClaimsFromStorage() {
  localStorage.removeItem('userClaims');
}

/**
 * Send a `logoutEvent` to local-storage.
 * It can be used by apps that need to have side-effects upon logout.
 */
function sendLogoutEvent() {
  localStorage.setItem('logoutEvent', Date.now());
  localStorage.removeItem('logoutEvent');
}

export function logoutAndInvalidate(
  options = {
    withRedirectToRoot: true,
  },
) {
  sendLogoutEvent();
  clearTokenFromStorage();
  clearPathnameFromStorage();
  clearExternalPatientIdFromStorage();
  clearUserClaimsFromStorage();

  // The default withRedirectToRoot option is true this option will redirect user back to initial route under the assumption
  // each App consuming will handle routing logic of it's own
  if (options.withRedirectToRoot) history.push('/');
}

function getAuthEndpointUrlByEnv(env) {
  switch (env) {
    // Support different production deployments
    case 'prod':
    case 'prod-uk':
    case 'prod-us':
      return 'https://accounts-production.healthy.io/authorize';
    // dr stands for disaster recovery and is a special env DevOps use
    case 'dr':
      return 'https://accounts-staging.healthy.io/authorize';
    default:
      return 'https://accounts-staging.healthy.io/authorize';
  }
}

/** Enable deep linking support for when logging in */
function enableDeepLinkingSupport() {
  const { pathname, search } = window.location;

  if (pathname !== '/') {
    savePathnameToStorage(pathname + search);
  }
}
// Check for pathname saved in storage.
// If it exists replace the URL then clear the storage.
export function replaceAndRedirectUrl() {
  const redirectUrl = getPathnameFromStorage();
  if (redirectUrl) {
    history.replace(redirectUrl);
  }
  clearPathnameFromStorage();
}

export function getLoginUrl({
  clientId,
  redirectUri,
  email,
  enableDeepLinking,
}) {
  const umBaseUrl = getAuthEndpointUrlByEnv(process.env.HEALTHY_ENV);

  if (enableDeepLinking) {
    enableDeepLinkingSupport();
  }

  return `${umBaseUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&email=${email}`;
}

/**
 * @description Return a URL to redirect the consuming app.
 * includes query params that gets picked up by UM and in return can add extra information to userClaims payload.
 * For example 'externalProviderId' for "epic" or "cerner"
 *
 * @param {string} partner - One of EXTERNAL_PROVIDERS
 * @param {object} params - additional params
 * @param {string} params.clientId - Client ID from UM
 * @param {string} params.redirectUri - Redirect uri from UM
 * @param {string} params.ap - Authentication provider
 * @param {string?} params.iss - Query param
 * @param {string?} params.launch - Query param
 *
 * @returns {string}
 */
export function createUMExternalAuthProviderUrl(partner, params) {
  const umBaseUrl = getAuthEndpointUrlByEnv(process.env.HEALTHY_ENV);
  let url;
  switch (partner) {
    case EXTERNAL_PROVIDERS.EPIC:
      url = `${umBaseUrl}?client_id=${params.clientId}&redirect_uri=${params.redirectUri}&ap=${params.ap}&iss=${params.iss}&launch=${params.launch}`;
      break;
    case EXTERNAL_PROVIDERS.CERNER:
      url = `${umBaseUrl}?client_id=${params.clientId}&redirect_uri=${params.redirectUri}&ap=${params.ap}&iss=${params.iss}&launch=${params.launch}`;
      break;
    case EXTERNAL_PROVIDERS.MCB:
      url = `${umBaseUrl}?client_id=${params.clientId}&redirect_uri=${params.redirectUri}&ap=${params.ap}`;
      break;
    case EXTERNAL_PROVIDERS.DR_CHRONO:
      url = `${umBaseUrl}?client_id=${params.clientId}&redirect_uri=${params.redirectUri}&ap=${params.ap}&jwt=${params.jwt}`;
      break;
    default:
      break;
  }
  // Support deep linking url when logging in
  enableDeepLinkingSupport();
  return url;
}
