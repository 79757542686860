import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import ButtonBase from '../ButtonBase/ButtonBase';
import { Intents, Sizes } from '../../constants';
import { spacing, colors, fontSizes, borderRadius } from '../../theme';
import { toRem } from '../../helpers';
import useThemeContext from '../Theme/useThemeContext';

const Appearances = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
};

const activeCss = (color) => css`
  :active {
    background-color: ${color};
  }
`;

const focusCss = (color) => css`
  :focus {
    box-shadow: 0 0 0 ${toRem(3)} ${color};
  }
`;

const hoverCss = (color) => css`
  :hover {
    background-color: ${color};
  }
`;

const buttonCss = (props) => css`
  /* Root */
  padding-inline: ${toRem(16)};
  border: ${toRem(1)} solid transparent;
  padding-inline: ${spacing.space4};
  border-radius: ${borderRadius.lg};
  font-weight: 500;
  font-size: ${fontSizes.body2};
  flex-shrink: 0;
  cursor: pointer;

  :focus-visible {
    outline: 0;
  }

  /* Pill-shaped */
  ${props.isPill &&
  css`
    border-radius: ${toRem(24)};
  `}

  /* Text appearance */
  ${props.appearance === Appearances.TEXT &&
  css`
    color: ${colors.text};

    ${focusCss(colors.primary300)}

    :hover,:active {
      backdrop-filter: brightness(95%);
    }
  `}

  /* Contained appearance */
  ${props.appearance === Appearances.CONTAINED &&
  props.intent === Intents.DANGER &&
  css`
    color: ${colors.white};
    background-color: ${colors.danger};

    ${hoverCss(colors.danger600)}
    ${focusCss(colors.danger300)}
    ${activeCss(colors.danger700)}
  `}

  ${props.appearance === Appearances.CONTAINED &&
  props.intent === Intents.PRIMARY &&
  css`
    background-color: ${colors.primary};
    color: ${colors.white};

    ${hoverCss(colors.primary500)}
    ${focusCss(colors.primary300)}
    ${activeCss(colors.primary600)}
  `}

  ${props.appearance === Appearances.CONTAINED &&
  props.intent === Intents.SUCCESS &&
  css`
    background-color: ${colors.success};
    color: ${colors.white};

    ${hoverCss(colors.success600)}
    ${focusCss(colors.success300)}
      ${activeCss(colors.success700)}
  `}

  /* Outlined appearance */
  ${props.appearance === Appearances.OUTLINED &&
  css`
    background-color: transparent;
    border-color: currentColor;
  `}

  ${props.appearance === Appearances.OUTLINED &&
  props.intent === Intents.DANGER &&
  css`
    color: ${colors.danger};

    ${hoverCss(colors.danger100)}
    ${focusCss(colors.danger300)}
    ${activeCss(colors.danger200)}
  `}

  ${props.appearance === Appearances.OUTLINED &&
  props.intent === Intents.PRIMARY &&
  css`
    color: ${colors.primary};

    ${hoverCss(colors.blueGray100)}
    ${focusCss(colors.primary300)}
    ${activeCss(colors.blueGray200)}
  `}

  ${props.appearance === Appearances.OUTLINED &&
  props.intent === Intents.SUCCESS &&
  css`
    color: ${colors.success};

    ${hoverCss(colors.success100)}
    ${focusCss(colors.success300)}
    ${activeCss(colors.success200)}
  `}

   /* Small button */
  ${props.size === Sizes.SM &&
  css`
    padding-inline: ${spacing.space4};
    height: ${toRem(32)};
    font-size: ${fontSizes.body2};
    gap: ${spacing.space1};

    &.HioButton--icon-end {
      padding-inline-end: ${spacing.space3};
    }

    &.HioButton--icon-start {
      padding-inline-start: ${spacing.space3};
    }

    .HioButtonBase-icon > svg {
      font-size: ${toRem(20)};
    }
  `}

   /* Large button */
  ${props.size === Sizes.LG &&
  css`
    padding-inline: ${spacing.space5};
    height: ${toRem(40)};
    font-size: ${fontSizes.body2};
    gap: ${spacing.space2};

    &.HioButton--icon-end {
      padding-inline-end: ${spacing.space4};
    }

    &.HioButton--icon-start {
      padding-inline-start: ${spacing.space4};
    }

    .HioButtonBase-icon > svg {
      font-size: ${toRem(20)};
    }
  `}

  /* Icon-only */
  ${props.isIcon &&
  css`
    min-width: unset;
    padding: unset;
    gap: unset;
  `}

  /* Small icon */
  ${props.isIcon &&
  props.size === Sizes.SM &&
  css`
    width: ${toRem(32)};
    height: ${toRem(32)};
    font-size: ${toRem(20)};
  `}

  /* Large icon */
  ${props.isIcon &&
  props.size === Sizes.LG &&
  css`
    width: ${toRem(40)};
    height: ${toRem(40)};
    font-size: ${toRem(20)};
  `}
`;

const Button = forwardRef((props, ref) => {
  const { defaultProps } = useThemeContext();

  const {
    as,
    appearance = defaultProps?.Button?.appearance ?? 'contained',
    ariaLabel,
    children,
    className,
    endIcon,
    isDisabled = false,
    isFullWidth = false,
    isIcon,
    isPill = defaultProps?.Button?.isPill ?? false,
    intent = defaultProps?.Button?.intent ?? Intents.PRIMARY,
    onClick,
    size = Sizes.LG,
    startIcon,
    type = 'button',
    value,
    ...restProps
  } = props;

  return (
    <ButtonBase
      as={as}
      ariaLabel={ariaLabel}
      css={buttonCss({ appearance, intent, isIcon, isPill, size })}
      className={cx([
        'HioButton-root',
        {
          [`HioButton--${appearance}`]: appearance,
          [`HioButton--${intent}`]: intent,
          [`HioButton--${size}`]: size,
          'HioButton--pill': isPill,
          'HioButton--icon-end': Boolean(endIcon),
          'HioButton--icon-only': isIcon,
          'HioButton--icon-start': Boolean(startIcon),
        },
        className,
      ])}
      endIcon={endIcon}
      isDisabled={isDisabled}
      isFullWidth={isFullWidth}
      onClick={onClick}
      ref={ref}
      startIcon={startIcon}
      type={type}
      value={value}
      {...restProps}
    >
      {children}
    </ButtonBase>
  );
});

Button.propTypes = {
  as: PropTypes.elementType,
  appearance: PropTypes.oneOf([
    Appearances.CONTAINED,
    Appearances.OUTLINED,
    Appearances.TEXT,
  ]),
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  endIcon: PropTypes.node,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  /** Change button's styling to look like an icon-only button */
  isIcon: PropTypes.bool,
  isPill: PropTypes.bool,
  intent: PropTypes.oneOf(['danger', 'primary', 'success']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'lg']),
  startIcon: PropTypes.node,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  value: PropTypes.string,
};

export default Button;
