import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { spacing, zIndex } from '../../../theme';
import Paper from '../../Paper/Paper';

const menuCss = css`
  position: absolute;
  z-index: ${zIndex.dropdown};
  width: 100%;
  margin-top: ${spacing.space1};

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);

  .HioSelect__menu-list {
    padding: 0;
    max-height: 340px;
  }
`;

function Menu(props) {
  const {
    children,
    innerRef,
    innerProps,
    selectProps: { inputValue, isSearchable, options },
  } = props;

  if (isSearchable && inputValue.length === 0 && options.length === 0) {
    return null;
  }

  return (
    <Paper
      css={menuCss}
      className={cx('HioSelect__menu')}
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  innerProps: PropTypes.objectOf(PropTypes.any),
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
    isSearchable: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }),
};

export default Menu;
